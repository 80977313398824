import { Button, Col, Form, Input, Modal, Row, message, Select, Image, Space, DatePicker, Table, Spin } from "antd";
import { useForm } from 'antd/lib/form/Form';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from 'services';
import { enumToOptions, timeL2S, selectFuzzyFilter } from "../utils/common";
import { CHANGE_MEMBER_METHOD } from "../enum/role";
import errorIcon from 'assets/image/erroricon.png';
import dayjs from "dayjs";
import { RESPONSE_CODE, RESPONSE_CODE_REBATE } from "constants/response";
import * as common from "../utils/common";
import { CASHBACK_TYPE } from "../enum/rebate";
import { LayoutPagination, DatePickerCol } from "./layout.component";
import { REGEX_ACCOUNT } from 'constants/regex';
import { COMMISSION_SETTLE_CYCLE } from "enum/state";


// 全民代理查詢-換線彈窗
export const PopupExchangeLine = ({ isOpen, close, mutate}: any) => {
  const [form] = useForm();
  const [levelFailAccount, setLevelFailAccount] = useState("");
  const [memberCount, setMemberCount] = useState("");

  const onFinish = (formData: any) => {
    const url =
      changeMethod === CHANGE_MEMBER_METHOD.memberAccount
        ? 'api/peopleagent/changemember'
        : 'api/peopleagent/changemember/direct';

    const memberAccountsArray = formData?.memberAccounts?.split(',').map((account: string) => account.trim());

    const sendData =
      changeMethod === CHANGE_MEMBER_METHOD.memberAccount
        ? {
          MemberAccounts: memberAccountsArray,
          TargetMemberAccount: formData.targetMemberAccount,
        }
        : {
          MemberAccount: formData.memberAccount,
          TargetMemberAccount: formData.targetMemberAccount,
        };

    $post({
      url,
      send: sendData,
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      fail: (response: any) => {
        switch (response.Message) {
          case 'LEVEL_FAIL':
            setIsOpenFail(true);
            setLevelFailAccount(changeMethod === CHANGE_MEMBER_METHOD.memberAccount
              ? response?.Data?.Account : convertToCommaSeparatedString(peopleAgentDirectMemberList?.Data, 'Account'));
            break;
          case 'INCORRECT_PARAMS':
            message.error(i18n.t('incorrectParams'));
            break;
          // 會員
          case 'MENBER_NOT_FOUND':
            message.error(`${i18n.t('notFound')}${i18n.t('memberAccount')}`);
            break;
          // 換線會員
          case 'CHANGE_MEMBER_NOT_FOUND':
            message.error(`${i18n.t('notFound')}${i18n.t('currencyExchange')}${i18n.t('memberAccount')}`);
            break;
          // 目標會員
          case 'TARGET_MEMBER_NOT_FOUND':
            message.error(`${i18n.t('notFound')}${i18n.t('targetMemberAccount')}`);
            break;
          default:
            message.error(RESPONSE_CODE_REBATE[response.Message as keyof typeof RESPONSE_CODE_REBATE]);
        }
      },
      showMessage: false
    });
  };

  const onClose = () => {
    form.resetFields();
    close();
    setChangeMethod(null)
    setMemberCount("");
  }

  // 換線方式相關
  const [changeMethod, setChangeMethod] = useState(null);

  // 取得線下直屬會員帳號相關
  const [keyword, setKeyword] = useState("")
  const [keywordTemp, setKeywordTemp] = useState("")

  const { data: peopleAgentDirectMemberList, isValidating } = $get({
    url: 'api/peopleagent/direct/member/list',
    params: {
      MemberAccount: keyword
    },
    allow: !!keyword
  })

  // 聖經: 延遲搜尋
  useEffect(() => {
    const handler = setTimeout(() =>  setKeyword(keywordTemp), 800);
    return () => clearTimeout(handler);
  }, [keywordTemp]);

  const convertToCommaSeparatedString = (arr: any, key: any) => arr?.map((item: any) => item[key]).join(', ');

  useEffect(() => {
    form.setFieldValue('offlineDirectMemberAccounts', convertToCommaSeparatedString(peopleAgentDirectMemberList?.Data, 'Account'));
    setMemberCount(peopleAgentDirectMemberList?.Data?.length || "0");
  }, [keyword, peopleAgentDirectMemberList]);

  const [isOpenFail, setIsOpenFail] = useState(false);

  return (
    <>
      <Modal
        title={i18n.t('setting')}
        open={isOpen}
        onCancel={onClose}
        centered
        width={450}
        footer={false}
        forceRender
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
            <Row gutter={[12, 12]}>

              <Col span={24}>
                <Form.Item  name="changeMemberMethod" label={i18n.t('changeMemberMethod')} rules={[{ required: true }]}>
                  <Select
                    placeholder={i18n.t('pleaseSelect')}
                    options={[
                      ...enumToOptions(CHANGE_MEMBER_METHOD)
                    ]}
                    onChange={setChangeMethod}
                  />
                </Form.Item>
              </Col>
              {changeMethod === CHANGE_MEMBER_METHOD.memberAccount &&
                <Col span={24}>
                  <Form.Item name="memberAccounts" label={`${i18n.t('currencyExchange')}${i18n.t('memberAccount')}`} rules={[{ required: true },
                    {
                      validator: (_, value) => {
                        // 如果值為空，讓必填規則處理
                        if (!value) {
                          return Promise.resolve();
                        }
                        const accounts = value.split(',').map((account: any) => account.trim()); // 使用逗號分隔帳號
                        // 如果帳號數量超過50筆跳錯誤提示
                        if (accounts.length > 50) {
                          return Promise.reject(new Error(`${i18n.t('maximumOf50MembershipAccounts')}`));
                        }
                        const invalidAccount = accounts.find((account: any) => !REGEX_ACCOUNT.test(account));
                        // 如果發現不符合格式的帳號，拒絕驗證並返回錯誤信息
                        if (invalidAccount) {
                          return Promise.reject(new Error(`${i18n.t('INCORRECT_ACCOUNT_FORMAT')}`));
                        }
                        return Promise.resolve(); // 所有帳號格式正確，通過驗證
                      },
                    },
                  ]}>
                    <Input.TextArea placeholder={`${i18n.t('memberAccount')}${i18n.t('pleaseUseCommaToSeparateTheItems')}`} autoSize={{ minRows: 1, maxRows: 2 }}/>
                  </Form.Item >
                </Col>
              }
              {changeMethod === CHANGE_MEMBER_METHOD.allMembersDirectlyUnderTheLine &&
                <Col span={24}>
                  <Form.Item name="memberAccount" label={i18n.t('memberAccount')} rules={[{ required: true },
                    {
                      validator: (_, value) => {
                        // 如果值為空，讓必填規則處理
                        if (!value) {
                          return Promise.resolve();
                        }
                        const accounts = value.split(',').map((account: any) => account.trim()); // 使用逗號分隔帳號
                        const invalidAccount = accounts.find((account: any) => !REGEX_ACCOUNT.test(account));
                        // 如果發現不符合格式的帳號，拒絕驗證並返回錯誤信息
                        if (invalidAccount) {
                          return Promise.reject(new Error(`${i18n.t('INCORRECT_ACCOUNT_FORMAT')}`));
                        }
                        return Promise.resolve(); // 所有帳號格式正確，通過驗證
                      },
                    }
                  ]}>
                    <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setKeywordTemp(e.target.value)}/>
                  </Form.Item >
                </Col>
              }
              {changeMethod === CHANGE_MEMBER_METHOD.allMembersDirectlyUnderTheLine &&
                <Col span={24}>
                  <Spin spinning={isValidating}>
                    <Form.Item name="offlineDirectMemberAccounts" label={`${i18n.t('offlineDirectMemberAccounts')}，${i18n.t('totalCounts')}：${memberCount}`} >
                      <Input.TextArea disabled placeholder={`${i18n.t('systemWillAutomaticallyBringIn')}`}
                                      autoSize={{ minRows: 1, maxRows: 5 }}/>
                    </Form.Item >
                  </Spin>
                </Col>
              }
              {(changeMethod === CHANGE_MEMBER_METHOD.memberAccount || changeMethod === CHANGE_MEMBER_METHOD.allMembersDirectlyUnderTheLine )&&
                <Col span={24}>
                  <Form.Item name="targetMemberAccount" label={i18n.t('targetMemberAccount')} rules={[{ required: true },
                    {
                      validator: (_, value) => {
                        // 如果值為空，讓必填規則處理
                        if (!value) {
                          return Promise.resolve();
                        }
                        const accounts = value.split(',').map((account: any) => account.trim()); // 使用逗號分隔帳號
                        const invalidAccount = accounts.find((account: any) => !REGEX_ACCOUNT.test(account));
                        // 如果發現不符合格式的帳號，拒絕驗證並返回錯誤信息
                        if (invalidAccount) {
                          return Promise.reject(new Error(`${i18n.t('INCORRECT_ACCOUNT_FORMAT')}`));
                        }
                        return Promise.resolve(); // 所有帳號格式正確，通過驗證
                      },
                    }]}>
                    <Input placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item >
                </Col>
              }
              <Col span={24}>
                <Row justify="center" className="mt-1" gutter={[12, 12]}>
                  <Col>
                    <Button key="cancel" onClick={onClose}>{i18n.t('cancel')}</Button>
                  </Col>
                  {
                    <Col>
                      <Button key="confirm" htmlType="submit" type="primary">{i18n.t('confirm')}</Button>
                    </Col>
                  }
                </Row>
              </Col>
            </Row>
        </Form>
      </Modal>
      <ChangerMemberFailWindow isOpen={isOpenFail} close={() => setIsOpenFail(false)} levelFailAccount={levelFailAccount}/>
    </>
  )
}

// 全民代理查詢-換線失敗彈窗
export const ChangerMemberFailWindow = ({ isOpen, close, levelFailAccount }: any) => {
  return (
    <Modal
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={errorIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{`${i18n.t('currencyExchange')}${i18n.t('fail')}`}</Col>
        </Row>
      }
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" className="mt-1" key="cancel" onClick={close}>
              {i18n.t('close')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('cannotTransferFirstLayer', { account: levelFailAccount })}</div>
      </Space>
    </Modal>
  )
}


// 全民代理返水查詢-全部派發
export const PopupAllpassPeopleAgentPromotionRebate = ({ isOpen, close, mutate, setAllPass, selectedRowKeys, setSelectedRowKeys }: {
  isOpen: boolean;
  close: () => void;
  mutate: () => void;
  setAllPass: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRowKeys: any;
  setSelectedRowKeys: any;
}) => {
  const [form] = useForm();
  const [date, setDate] = useState<any>(dayjs().format("YYYY-MM-DD"));

  const handleAllPass = (formData: any) => {
    $post({
      url: 'api/peopleagentcommission/pass/all',
      send: {
        Date: date
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
        setSelectedRowKeys([]);
      },
      resCode: RESPONSE_CODE_REBATE
    }, setAllPass)
  }

  const onClose = () => {
    form.resetFields();
    close();
  };

  return (
    <Modal
      title={i18n.t('distributeAll')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      footer={false}
      width={400}
      forceRender
    >
      <Form form={form} onFinish={handleAllPass} initialValues={{
        date: dayjs()
      }}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <div>
              <span className="require">*</span>
              <span className="color-03">
                {`${i18n.t('distributionTimeOnlyAllowsSelecting')}${i18n.t('oneDay')}`}
              </span>
            </div>
            <div>
              <span className="require">*</span>
              <span className="color-03">
                {i18n.t('rebatesWillNotBeDistributed')}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <Form.Item name="date" rules={[{ required: true, message: `${i18n.t('pleaseSelect')}` }]}>
              <DatePicker className="w-full" onChange={(date, dateString) => setDate(dateString)} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('distributeAll')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 全民代理返水查詢- 單筆個人返水返水重計/線下直屬有效會員返水重計 結果彈窗
export const PopupSingleRecountInfo = ({ isOpen, close, recountInfo }: any) => {
  const [columns, setColumns] = useState<any>([]);
  useEffect(() => {
    if (recountInfo && isOpen) {
      setColumns([
        {
          dataIndex: 'Code',
          title: i18n.t('order'),
          width: 230,
          fixed: 'left',
        },
        {
          dataIndex: 'MemberAccount',
          title: i18n.t('memberAccount'),
          width: 170,
        },
        {
          dataIndex: 'Type',
          title: i18n.t('cashbackType'),
          width: 220,
          render: (val: any) => i18n.t(CASHBACK_TYPE[val])
        },
        {
          dataIndex: 'PreAmount',
          title: `${i18n.t('rebate')}${i18n.t('amount')}`,
          width: 180,
          render: (val: any) => common.toFormatNumber(val)
        },
        {
          dataIndex: 'RecalculateAmount',
          title: i18n.t('rebateRecalculationAmount'),
          width: 180,
          render: (val: any) => common.toFormatNumber(val)
        },
      ])
    }
  }, [recountInfo, isOpen])

  return (
    <Modal
      title={`${i18n.t('date')} : ${dayjs(recountInfo?.PeriodStartDate).format('YYYY-MM-DD HH:mm:ss')} ~ ${ dayjs(recountInfo?.PeriodEndDate).format('YYYY-MM-DD HH:mm:ss')} `}
      open={isOpen}
      centered
      onCancel={close}
      width={1000}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" className="mt-1" key="cancel" onClick={close}>
              {i18n.t('close')}
            </Button>
          </Col>
        </Row>
      }
    >
      <Table
        size="small"
        dataSource={[recountInfo]}
        columns={columns}
        pagination={false}
      />
    </Modal>
  )
}

// 重計結果彈窗
export const PopupRecountInfo = ({ isOpen, close }: any) => {
  const [page, setPage] = useState<number[]>([1, 10]);

  // 取得重計結果
  const { data: recountInfo, isValidating } = $get({
    url: 'api/peopleagentcommission/recalculate/result',
    params: {
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: isOpen
  })

  // 更新返水重計結果已讀
  const handleReadRecountInfo = (formData: any) => {
    $post({
      url: 'api/peopleagentcommission/recalculate/result/read',
      success: () => {
        close()
      },
    })
  }

  return (
    <Modal
      title={
        recountInfo?.StartDate && recountInfo?.EndDate
          ? `${i18n.t('date')} : ${dayjs(recountInfo.StartDate).format('YYYY-MM-DD HH:mm:ss')} ~ ${dayjs(recountInfo.EndDate).format('YYYY-MM-DD HH:mm:ss')}`
          : `${i18n.t('date')} : `
      }
      open={isOpen}
      centered
      onCancel={handleReadRecountInfo}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button type="primary" className="mt-1" key="cancel" onClick={handleReadRecountInfo}>
              {i18n.t('close')}
            </Button>
          </Col>
        </Row>
      }
      width={1000}
    >
      <Table
        loading={isValidating}
        size="small"
        dataSource={recountInfo?.Data?.map((item: WalletRecordTYpe) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left',
          },
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 180,
          },

          {
            dataIndex: 'Type',
            title: i18n.t('cashbackType'),
            render: (val: any) => i18n.t(CASHBACK_TYPE[val])
          },
          {
            dataIndex: 'PreAmount',
            title: `${i18n.t('rebate')}${i18n.t('amount')}`,
            width: 180,
            render: (val: any) => common.toFormatNumber(val)
          },
          {
            dataIndex: 'RecalculateAmount',
            title: i18n.t('rebateRecalculationAmount'),
            width: 180,
            render: (val: any) => common.toFormatNumber(val)
          },
        ]}
        pagination={false}
      />
      <LayoutPagination total={recountInfo ? recountInfo.TotalRecord : 0} setPage={setPage} page={page} />
    </Modal>
  )
}

// 返水重計彈窗
export const PopupRebateRecount = ({ isOpen, close, mutate }: any) => {
  const [form] = useForm();
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    null
  ]);

  // 遊戲大類
  const { data: gameCategory } = $get({ url: 'api/category/list', params: { Status: 1 } })

  // 返水重計日期
  const { data: rebateRecountDateInfo } = $get({ url: 'api/peopleagentcommission/recalculate/date' })

  useEffect(() => {
    if (isOpen && rebateRecountDateInfo) {
      setDate([
        dayjs(rebateRecountDateInfo?.Data.StartDate).format('YYYY-MM-DD HH:mm:ss'), 
        dayjs(rebateRecountDateInfo?.Data.EndDate).format('YYYY-MM-DD HH:mm:ss'),
        null
      ]);
    }
  }, [isOpen, rebateRecountDateInfo])

  const onFinish = (formData: any) => {
    const memberAccountsArray = formData?.memberAccounts?.split(',').map((account: string) => account.trim());
    const categoryCodesArray = formData?.gameCategory?.filter((code: string) => code !== '');

    $post({
      url: 'api/peopleagentcommission/recalculate',
      send: {
        StartDate: timeL2S(date[0] as any),
        EndDate: timeL2S(date[1] as any),
        CategoryCodes: categoryCodesArray,
        MemberAccounts: memberAccountsArray
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        onClose();
        mutate();
      },
      resCode: RESPONSE_CODE_REBATE
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  const handleSelectChange = (value: string[]) => {
    if (value.includes('')) {
      const allValues = gameCategory?.Data?.map((item: any) => item.Code) || [];
      form.setFieldsValue({ gameCategory: ['', ...allValues] });
    } else {
      form.setFieldsValue({ gameCategory: value });
    }
  };

  const handleChange = (e: any) => {
    form.setFieldsValue({ [gameCategory]: e });
    if (handleSelectChange) {
      handleSelectChange(e);
    }
  };

  return (
    <Modal
      title={i18n.t('rebateRecalculation')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={420}
      footer={false}
      forceRender
    >
      <span className="require">*</span>
      <span className="color-03">
        {`${i18n.t("selectionOnly")}${i18n.t(COMMISSION_SETTLE_CYCLE[rebateRecountDateInfo?.Data?.CommissionSettleCycle])}${i18n.t("canOnlySettleLastPeriod")}`}
      </span>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item className="mt-1" label={i18n.t('date')} rules={[{ required: true }]} required>
          <Row gutter={[0, 12]}>
            <DatePickerCol width={186} name="dateRange" date={date} setDate={setDate} displayCom={['Picker']} disabled />
          </Row>
        </Form.Item>

        <Form.Item name="gameCategory" className="mt-1" label={
          <div>
            <span>{`${i18n.t('gameCategory')}`}</span>
            <span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
          </div>
        }>
          <Select
            mode="multiple"
            allowClear
            placeholder={i18n.t('pleaseSelect')}
            filterOption={selectFuzzyFilter}
            onChange={handleChange}
            options={[
              {value: '', label: `${i18n.t('ALL')}`},
            ...(gameCategory?.Data?.map((option: any) => ({
                value: option.Code,
                label: i18n.t(option.Name)
              })) || []
            )]}
          />
        </Form.Item>

        <Form.Item name="memberAccounts" className="mt-1" label={i18n.t('memberAccount')}
                   rules={[{ required: false },
                     {
                       validator: (_, value) => {
                         if (!value) {
                           return Promise.resolve();
                         }
                         const accounts = value.split(',').map((account: any) => account.trim()); // 使用逗號分隔帳號
                         // 如果帳號數量超過50筆跳錯誤提示
                         if (accounts.length > 50) {
                           return Promise.reject(new Error(`${i18n.t('maximumOf50MembershipAccounts')}`));
                         }
                         const invalidAccount = accounts.find((account: any) => !REGEX_ACCOUNT.test(account));
                         // 如果發現不符合格式的帳號，拒絕驗證並返回錯誤信息
                         if (invalidAccount) {
                           return Promise.reject(new Error(`${i18n.t('INCORRECT_ACCOUNT_FORMAT')}`));
                         }
                         return Promise.resolve(); // 所有帳號格式正確，通過驗證
                       },
                     },
                   ]}>
          <Input.TextArea
            placeholder={`${i18n.t('memberAccount')}${i18n.t('pleaseUseCommaToSeparateTheItems')}`}
            autoSize={{ minRows: 1, maxRows: 2 }}
          />
        </Form.Item>

        <Row className="mt-1" justify="center" gutter={[12, 12]}>
          <Col>
            <Button onClick={onClose}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">{i18n.t('allRecalculate')}</Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

