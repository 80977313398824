import { Button, Col, Descriptions, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Spin, Table, UploadFile, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import hintIcon from 'assets/image/icon-12.png';
import { GATEWAY_CODE_DEPOSIT, GATEWAY_CODE_WITHDRAW } from "constants/gateway";
import { RESPONSE_CODE_ADD_DEPOSIT, RESPONSE_CODE_WITHDRAW, RESPONSE_CODE_FINANCE, RESPONSE_CODE_MERCHANT, RESPONSE_CODE_WITHDRAW_UPDATE } from "constants/response";
import { AGENT_ADJUST_STATE, DEPOSIT_STATE, ENABLE, GATEWAY_TYPE, MEMBER_BANK_STATE, MODE, TRANSFER_SETTING_STATUS, TRANSFER_TYPE, WITHDRAW_STATE } from "enum/state";
import useAccount from "hooks/account.hook";
import useSite from "hooks/site.hook";
import i18n from "i18n";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { $get, $post } from 'services';
import { setSearchHistory } from "slice/searchSlice";
import { RootType } from "store/configureStore";
import * as common from 'utils/common';
import { timeS2L, verify } from "utils/common";
import { CopyButton, InquiryWindow, LayoutTextArea, UploadImage } from "./layout.component";

export enum ORDER_TYPE {
  bank = 1,
  virtual = 2
}

const colorChange = (type: number) => {
  switch (type) {
    case TRANSFER_SETTING_STATUS.enabled:
      return 'color-pass'
    case TRANSFER_SETTING_STATUS.disable:
      return 'color-reject'
    case TRANSFER_SETTING_STATUS.delete:
      return 'color-padding'
  }
}

// 銀行卡驗證列表
export const ViewWithdrawList = ({ data, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const [activeId, setActiveId] = useState(0);
  const [isOpenReviewModal, setIsOpenReviewModal] = useState(false);

  // 狀態顏色
  const stateColor = (state: number) => {
    switch (state) {
      case MEMBER_BANK_STATE.verificationSuccessfulOpen:
        return 'color-pass'
      case MEMBER_BANK_STATE.verificationFailed:
        return 'color-reject'
      case MEMBER_BANK_STATE.waitVerify:
        return 'color-reviewing'
      default: return 'color-padding'
    }
  }

  const openDetail = (id: number) => {
    setIsOpenReviewModal(true);
    setActiveId(id);
  }

  return (
    <>
      <Table
        scroll={{ x: 1500 }}
        size="middle"
        dataSource={data?.Data?.map((item: any) => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            fixed: "left",
            width: 150
          },
          {
            dataIndex: 'RealName',
            title: i18n.t('realName'),
            width: 150,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Name',
            title: i18n.t('accountName'),
            width: 150
          },
          {
            dataIndex: 'PhoneNumber',
            title: i18n.t('phoneNumber'),
            width: 200,
            render: (val, { PhonePrefix }: any) => `${PhonePrefix}-${val}`
          },
          {
            dataIndex: 'Account',
            title: i18n.t('accountNumber'),
            width: 200,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'VerifiedDate',
            title: i18n.t('auditTime'),
            width: 200,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 200,
            fixed: 'right',
            render: (val, record: any) => (
              <>
                {
                  val
                    ? <div className={stateColor(val)}>{i18n.t(MEMBER_BANK_STATE[val])}</div>
                    : <div className={stateColor(val)}>{i18n.t(MEMBER_BANK_STATE[MEMBER_BANK_STATE.waitVerify])}</div>
                }
                {
                  val !== MEMBER_BANK_STATE.waitVerify &&
                  <div>{`${record.OperatorAccount || 'System'} ${i18n.t(MEMBER_BANK_STATE[val])}`}</div>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            width: 100,
            fixed: 'right',
            render: (_, record: any) => (
              <Button className="size-12" type="link" onClick={() => openDetail(record.key)}>
                {(record.Status === MEMBER_BANK_STATE.waitVerify && $p('10903')) ? i18n.t('audit') : i18n.t('detail')}
              </Button>
            )
          },
        ]}
        pagination={false}
      />
      <PopupWithdrawReviewModal isOpen={isOpenReviewModal} close={() => setIsOpenReviewModal(false)} id={activeId} mutate={mutate} />
    </>
  )
}
// 會員提款卡審核詳細
export const PopupWithdrawReviewModal = ({ isOpen, close, id, mutate }: any) => {
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [orderType, setOrderType] = useState<ORDER_TYPE>(ORDER_TYPE.bank);
  const { data: bankInfo, mutate: mutateInfo, isValidating } = $get({
    url: 'api/withdrawmethod/one',
    params: { Id: id },
    allow: !!id
  });
  const photo = JSON.parse(bankInfo?.Data.Photo || '[]');

  useEffect(() => {
    if (isOpen) {
      mutateInfo()
    }
  }, [isOpen]);
  useEffect(() => {
    if (bankInfo) {
      bankInfo?.Data.Crypto === ''
        ? setOrderType(ORDER_TYPE.bank)
        : setOrderType(ORDER_TYPE.virtual)
    }
  }, [bankInfo]);

  const submit = (type: number) => {
    const { remark } = form.getFieldsValue();
    $post({
      url: 'api/withdrawmethod/member/verify',
      send: {
        Id: id,
        Status: type,
        Remark: remark
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      },
      resCode: RESPONSE_CODE_WITHDRAW_UPDATE
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={bankInfo?.Data?.Status === MEMBER_BANK_STATE.waitVerify ? i18n.t('audit') : i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={800}
      footer={false}
      forceRender
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions size="small" layout="horizontal" column={2} bordered
            labelStyle={{ width: 80 }} contentStyle={{ width: 150 }}>
            <Descriptions.Item className="size-12" label={i18n.t('accountName')}>
              {bankInfo?.Data?.Name || '-'}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.bank ? i18n.t('accountNumber') : i18n.t('currency')
            }>
              {orderType === ORDER_TYPE.bank ? bankInfo?.Data?.Account : bankInfo?.Data?.Crypto}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.bank ? i18n.t('bankName') : i18n.t('networkType')
            }>
              {orderType === ORDER_TYPE.bank ? bankInfo?.Data?.BankName : bankInfo?.Data?.CryptoType}
            </Descriptions.Item>
            <Descriptions.Item className="size-12" label={
              orderType === ORDER_TYPE.bank ? i18n.t('openingBranch') : i18n.t('receiveAddress')
            }>
              {orderType === ORDER_TYPE.bank ? bankInfo?.Data?.Branch : bankInfo?.Data?.CryptoAddress}
            </Descriptions.Item>
            {
              orderType === ORDER_TYPE.bank &&
              <>
                <Descriptions.Item className="size-12" label={i18n.t('bankCounty')}>
                  {bankInfo?.Data?.State || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('city')}>
                  {bankInfo?.Data?.City || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('idNum')}>
                  {bankInfo?.Data?.IDNumber || '-'}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('realName')}>
                  {bankInfo?.Data?.MemberName}
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('remark')}>
                  {bankInfo?.Data?.Status === MEMBER_BANK_STATE.waitVerify && $p('10903')
                    ? <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t('rejectionRequiresRemark')}` }]} style={{ minHeight: '135px' }}>
                      <Input.TextArea
                        disabled={bankInfo?.Data?.Status !== MEMBER_BANK_STATE.waitVerify}
                        placeholder={`${i18n.t('inputData')}`}
                        autoSize={{ maxRows: 2, minRows: 5 }}
                        maxLength={100}
                        showCount
                      />
                    </Form.Item>
                    : bankInfo?.Data?.Remark
                  }
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('image')}>
                  {
                    photo.length !== 0 &&
                    <Image src={photo[0]} height={100} />
                  }
                </Descriptions.Item>
              </>
            }
            {
              orderType === ORDER_TYPE.virtual &&
              <>
                <Descriptions.Item className="size-12" label={i18n.t('QR Code')}>
                  {
                    photo.length !== 0 &&
                    <Image src={photo[0]} height={100} />
                  }
                </Descriptions.Item>
                <Descriptions.Item className="size-12" label={i18n.t('remark')}>
                  {bankInfo?.Data?.Status === MEMBER_BANK_STATE.waitVerify && $p('10903')
                    ? <Form.Item name="remark" rules={[{ required: true, message: `${i18n.t('rejectionRequiresRemark')}` }]}>
                      <Input.TextArea
                        disabled={bankInfo?.Data?.Status !== MEMBER_BANK_STATE.waitVerify}
                        placeholder={`${i18n.t('inputData')}`}
                        maxLength={225}
                        autoSize={{ maxRows: 2, minRows: 4 }}
                      />
                    </Form.Item>
                    : bankInfo?.Data?.Remark
                  }
                </Descriptions.Item>
              </>
            }
          </Descriptions>
          <Row justify="center" gutter={[12, 12]}>
            {
              bankInfo?.Data?.Status === MEMBER_BANK_STATE.waitVerify && $p('10903')
                ? <>
                  <Col>
                    <Button className="mt-1" type="primary" onClick={() => submit(MEMBER_BANK_STATE.verificationFailed)} danger>
                      {i18n.t('reject')}
                    </Button>
                  </Col>
                  <Col>
                    <Button className="mt-1" type="primary" onClick={() => submit(MEMBER_BANK_STATE.verificationSuccessfulOpen)}>
                      {i18n.t('approvaled')}
                    </Button>
                  </Col>
                </>
                : <Button className="mt-1" type="primary" onClick={onClose}>{i18n.t('close')}</Button>
            }
          </Row>
        </Spin>
      </Form>
    </Modal>
  )
}

// 會員入款審核
export const PopupDepositAudit = ({ isOpen, close, id, mutate }: any) => {
  const [form] = useForm();
  const { data: depositDetail, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/depositticket/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const handlePass = () => {
    const { bnRemarks } = form.getFieldsValue();
    $post({
      url: 'api/depositticket/member/resolve',
      send: {
        Id: depositDetail?.Data.Id,
        RealAmount: depositDetail?.Data.Amount,
        BnRemarkFromFin: bnRemarks,
      },
      success: () => {
        message.success(i18n.t('approvaled'));
        onClose();
        mutate();
      },
      fail: (response: any) => {
        message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
        if (response.Message === 'TICKET_STATUS_FORBIDDEN') {
          mutate();
          onClose();
        }
      },
      resCode: RESPONSE_CODE_FINANCE,
      showMessage: false
    })
  }

  const handleReject = () => {
    const { bnRemarks } = form.getFieldsValue();
    $post({
      url: 'api/depositticket/member/reject',
      send: {
        Id: depositDetail?.Data.Id,
        RemarkFromFin: bnRemarks,
      },
      success: () => {
        message.success(i18n.t('reject'));
        onClose();
        mutate();
      },
      fail: (response: any) => {
        message.error(RESPONSE_CODE_FINANCE[response.Message as keyof typeof RESPONSE_CODE_FINANCE]);
        if (response.Message === 'TICKET_STATUS_FORBIDDEN') {
          mutate();
          onClose();
        }
      },
      resCode: RESPONSE_CODE_FINANCE,
      showMessage: false
    })
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  const isVirtual = (
    depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬
  );

  return (
    <Modal
      title={`${i18n.t('deposit')}`}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      width={900}
      forceRender
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('reject')}</Button></Col>
          <Col><Button type="primary" onClick={handlePass}>{i18n.t('approvaled')}</Button></Col>
        </Row>
      }
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          {/* 第一段 */}
          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {depositDetail?.Data.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberAccount')}>
              <Row align="middle" justify="space-between">
                <Col> {depositDetail?.Data.OwnerAccount}</Col>
                <Col><CopyButton text={depositDetail?.Data.OwnerAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('agent')}`}>
              {depositDetail?.Data.AgentAccount}
            </Descriptions.Item>
            <Descriptions.Item label={`${i18n.t('memberName')}`}>
              {depositDetail?.Data.MemberName || '-'}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberGroup')}>
              {depositDetail?.Data.MemberGroupName}
            </Descriptions.Item>
            {/*對齊用*/}
            <Descriptions.Item >
              <></>
            </Descriptions.Item>
          </Descriptions>

          {/* 第二段 */}
          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {/* 入款金額 */}
            <Descriptions.Item label={i18n.t('depositAmountMoney')}>
              {common.toFormatNumber(depositDetail?.Data.Amount)}
            </Descriptions.Item>
            {
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬
                ? <>
                  <Descriptions.Item label={i18n.t('currency')}>
                    {depositDetail?.Data.OfflineInfo.Crypto}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('networkType')}>
                    {depositDetail?.Data.OfflineInfo.CryptoType}
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('depositMethod')}>
                    {depositDetail?.Data.DepositMerchantGatewayCode
                      ? i18n.t(depositDetail.Data.DepositMerchantGatewayCode)
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('depositInformation')}>
                    {
                      (
                        depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 ||
                        depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下銀行) ?
                        <>
                          <div>{depositDetail?.Data.OfflineInfo.Name || '-'}</div>
                          <div>
                            <span>{depositDetail?.Data.OfflineInfo.BankName}</span>／
                            <span>{common.displayLastFourCharacters(depositDetail?.Data.OfflineInfo.Account) || '-'}</span>／
                            <span>{depositDetail?.Data.OfflineInfo.PhoneNumber || '-'}</span>
                          </div>
                        </>
                        :
                        <>
                          <div>{depositDetail?.Data.MerchantInfo.CustomName || '-'}</div>
                        </>
                    }
                  </Descriptions.Item>
                </>
            }
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber(depositDetail?.Data.HandlingFee)}／{
                isVirtual ? common.toFormatNumber(depositDetail?.Data.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
            {
              depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 &&
              <>
                {/* 匯率換算 */}
                <Descriptions.Item label={`≈ ${depositDetail?.Data.OfflineInfo.Crypto}`}>
                  {common.toFormatNumber(depositDetail?.Data.Amount / depositDetail?.Data.ExchangeRate, 2, 'ceil')}
                </Descriptions.Item>
                <Descriptions.Item label={''}>
                  <></>
                </Descriptions.Item>
                {/* 收款地址 */}
                <Descriptions.Item label={i18n.t('receiveAddress')} span={2}>
                  {depositDetail?.Data.OfflineInfo.CryptoAddress}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('QR Code')}>
                  {
                    depositDetail?.Data.OfflineInfo.QR_Code
                      ? <Image src={depositDetail?.Data.OfflineInfo.QR_Code || ''} height={100} />
                      : '-'
                  }
                </Descriptions.Item>
                <Descriptions.Item label={''}>
                  <></>
                </Descriptions.Item>
              </>
            }
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {
                depositDetail && depositDetail?.Data.Status === DEPOSIT_STATE.success && (
                  depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方ATM ||
                  depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方超商 ||
                  depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方銀行
                ) ? i18n.t('system') : (depositDetail?.Data.FinanaceAccount || '-')
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {i18n.t(DEPOSIT_STATE[depositDetail?.Data.Status])}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>
              {timeS2L(depositDetail?.Data.CreateDate)}
            </Descriptions.Item>
            <Descriptions.Item label={''}>
              <></>
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            className="pt-1"
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <LayoutTextArea formName="bnRemarks" />
            </Descriptions.Item>
            {
              depositDetail && (
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方ATM ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方超商 ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方銀行 ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下銀行
              ) &&
              <Descriptions.Item label={i18n.t('certificate')}>
                {
                  depositDetail?.Data.ReceiptPhoto
                    ? <Image className="center" src={depositDetail?.Data.ReceiptPhoto} height={100} />
                    : '-'
                }
              </Descriptions.Item>
            }
          </Descriptions>
        </Spin>
      </Form>
    </Modal>
  )
}
// 會員入款詳細
export const PopupDepositInfo = ({ isOpen, close, id, mutate }: any) => {
  const [remarks, setRemarks] = useState('');
  const { permissions: $p } = useAccount();
  const { data: depositDetail, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/depositticket/one',
    params: { Id: id },
    allow: !!id
  })
  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const handlePass = () => {
    $post({
      url: 'api/depositticket/member/resolve',
      send: {
        Id: depositDetail?.Data.Id,
        RealAmount: depositDetail?.Data.Amount,
        BnRemarkFromFin: remarks
      },
      success: () => {
        message.success(i18n.t('approvaled'));
        onClose();
      },
      resCode: RESPONSE_CODE_FINANCE,
    })
  }

  const handleReject = () => {
    $post({
      url: 'api/depositticket/member/reject',
      send: {
        Id: depositDetail?.Data.Id,
        RemarkFromFin: remarks
      },
      success: () => {
        message.success(i18n.t('reject'));
        onClose();
      },
      resCode: RESPONSE_CODE_FINANCE,
    })
  }

  const onClose = () => {
    setRemarks('');
    close();
    mutate();
  }

  const isVirtual = (
    depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬
  );
  const cvsInfo = JSON.parse(depositDetail?.Data.Memo || '{}');
  const cvsName = cvsInfo?.Name

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      centered={true}
      onCancel={onClose}
      width={900}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          {
            (depositDetail?.Data.Status === DEPOSIT_STATE.paying || depositDetail?.Data.Status === DEPOSIT_STATE.fail) && $p('20203')
              ? <>
                <Col><Button type="primary" danger onClick={handleReject}>{i18n.t('forceReject')}</Button></Col>
                <Col><Button type="primary" onClick={handlePass}>{i18n.t('forceApproval')}</Button></Col>
              </>
              : <Col><Button type="primary" onClick={close}>{i18n.t('close')}</Button></Col>
          }
        </Row >
      }
    >
      <Spin spinning={isValidating}>
        <Descriptions
          bordered
          size="small"
          column={2}
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('order')}>
            {depositDetail?.Data.Code}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberAccount')}>
            <Row align="middle" justify="space-between">
              <Col> {depositDetail?.Data.OwnerAccount}</Col>
              <Col><CopyButton text={depositDetail?.Data.OwnerAccount} /></Col>
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label={`${i18n.t('agent')}`}>
            {depositDetail?.Data.AgentAccount}
          </Descriptions.Item>
          <Descriptions.Item label={`${i18n.t('memberName')}`}>
            {depositDetail?.Data.MemberNickName || depositDetail?.Data.MemberName || '-'}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('memberGroup')}>
            {depositDetail?.Data.MemberGroupName}
          </Descriptions.Item>
          {/*對齊用*/}
          <Descriptions.Item >
            <></>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('depositAmountMoney')}>
            {common.toFormatNumber(depositDetail?.Data.Amount)}
          </Descriptions.Item>
          {
            depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬
              ?
              <>
                <Descriptions.Item label={i18n.t('currency')}>
                  {depositDetail?.Data.OfflineInfo.Crypto}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('networkType')}>
                  {depositDetail?.Data.OfflineInfo.CryptoType}
                </Descriptions.Item>
              </>
              :
              <>
                <Descriptions.Item label={i18n.t('depositMethod')}>
                  {depositDetail?.Data.DepositMerchantGatewayCode
                    ? i18n.t(depositDetail.Data.DepositMerchantGatewayCode)
                    : '-'}
                </Descriptions.Item>
                <Descriptions.Item label={i18n.t('depositInformation')}>
                  {
                    depositDetail && (
                      depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 ||
                      depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下銀行) ?
                      <>
                        <div>{depositDetail?.Data.OfflineInfo.Name || '-'}</div>
                        <div>
                          <span>{depositDetail?.Data.OfflineInfo.BankName}</span>／
                          <span>{common.displayLastFourCharacters(depositDetail?.Data.OfflineInfo.Account) || '-'}</span>／
                          <span>{depositDetail?.Data.OfflineInfo.PhoneNumber || '-'}</span>
                        </div>
                      </>
                      :
                      <>
                        <div>{depositDetail?.Data.MerchantInfo.CustomName || '-'}</div>
                        <div>{cvsName}</div>
                      </>
                  }
                </Descriptions.Item>
              </>

          }
          <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
            {common.toFormatNumber(depositDetail?.Data.HandlingFee)}／{
              isVirtual ? common.toFormatNumber(depositDetail?.Data.ExchangeRate || 0, 2) : '-'}
          </Descriptions.Item>
          {
            depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下虛擬 &&
            <>
              <Descriptions.Item label={`≈ ${depositDetail?.Data.OfflineInfo.Crypto}`}>
                {common.toFormatNumber(depositDetail?.Data.Amount / depositDetail?.Data.ExchangeRate, 2, 'ceil')}
              </Descriptions.Item>
              <Descriptions.Item label={''}>
                <></>
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t('receiveAddress')} span={2}>
                {depositDetail?.Data.OfflineInfo.CryptoAddress}
              </Descriptions.Item>
              <Descriptions.Item label={i18n.t('QR Code')}>
                {
                  depositDetail?.Data.OfflineInfo.QR_Code
                    ? <Image src={depositDetail?.Data.OfflineInfo.QR_Code || ''} height={100} />
                    : '-'
                }
              </Descriptions.Item>
              <Descriptions.Item label={''}>
                <></>
              </Descriptions.Item>
            </>
          }
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('auditor')}>
            {
              depositDetail && depositDetail?.Data.Status === DEPOSIT_STATE.success && (
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方ATM ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方超商 ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方銀行
              ) ? 'System' : (depositDetail?.Data.FinanaceAccount || 'System')
            }
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('status')}>
            {i18n.t(DEPOSIT_STATE[depositDetail?.Data.Status])}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('createTime')}>
            {timeS2L(depositDetail?.Data.CreateDate)}
          </Descriptions.Item>
          <Descriptions.Item label={''}>
            <></>
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={2}
          className="pt-1"
          labelStyle={{ width: '18%' }}
          contentStyle={{ width: '32%' }}
        >
          <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
            {
              (depositDetail?.Data.Status === DEPOSIT_STATE.paying || depositDetail?.Data.Status === DEPOSIT_STATE.fail) && $p('20203')
                ? <Input placeholder={`${i18n.t('inputData')}`} onChange={e => setRemarks(e.target.value)}
                  value={remarks} />
                : depositDetail?.Data.BnRemark || ''
            }
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('certificate')}>
            {
              depositDetail && (
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方ATM ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方超商 ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.三方銀行 ||
                depositDetail?.Data.DepositMerchantGatewayCode === GATEWAY_CODE_DEPOSIT.線下銀行
              ) &&
              <Descriptions.Item label={i18n.t('certificate')}>
                {
                  depositDetail?.Data.ReceiptPhoto
                    ? <Image className="center" src={depositDetail?.Data.ReceiptPhoto} height={100} />
                    : '-'
                }
              </Descriptions.Item>
            }
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </Modal >
  )
}


// 會員出款總計
export const ViewMemberWithdrawOverview = ({ data }: {
  data: {
    State: string;
    TotalAmount: number;
    TotalHandlingFee: number;
    TotalRealAmount: number;
    TotalRecord: number;
  };
}) => {
  return (
    <Table
      size="middle"
      dataSource={
        data ?
          [
            {
              key: 1,
              count: data.TotalRecord,
              WithdrawalTotalAmount: data.TotalAmount,
              totalProcessingFee: data.TotalHandlingFee,
              totalActualWithdrawalAmount: data.TotalRealAmount
            }
          ] : []
      }
      columns={[
        {
          title: i18n.t('count'),
          dataIndex: 'count',
          align: 'right',
          width: '25%',
        },
        {
          title: i18n.t('withdrawalTotalAmount'),
          dataIndex: 'WithdrawalTotalAmount',
          align: 'right',
          width: '25%',
          render: (_, { WithdrawalTotalAmount }) => common.toFormatNumber(WithdrawalTotalAmount)
        },
        {
          title: i18n.t('totalProcessingFee'),
          dataIndex: 'totalProcessingFee',
          align: 'right',
          width: '25%',
          render: (_, { totalProcessingFee }) => common.toFormatNumber(totalProcessingFee)
        },
        {
          title: i18n.t('totalActualWithdrawalAmount'),
          dataIndex: 'totalActualWithdrawalAmount',
          align: 'right',
          width: '25%',
          render: (_, { totalActualWithdrawalAmount }) => common.toFormatNumber(totalActualWithdrawalAmount)
        },
      ]}
      pagination={false} />
  )
}
// 會員提款查詢
export const ViewMemberWithdrawList = ({ data, mutate }: {
  data: {
    State: string;
    Data: MemberWithdrawListType[];
  },
  mutate: () => void;
}) => {
  const { info, permissions: $p } = useAccount();
  const [id, setId] = useState<number | null>(0);
  const [financeId, setFinanceId] = useState<number | null>(0);
  const [orderState, setOrderState] = useState(0);
  const [orderType, setOrderType] = useState<string | null>('');
  const [isOpenInfo, setIsOpenInfo] = useState(false);
  const [isOpenAudit, setIsOpenAudit] = useState(false);
  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);

  const showModalConfirm = (id: number, orderState: number) => {
    setId(id);
    setOrderState(orderState);
    setIsOpenAudit(true);
  };

  const showModalDetail = (id: number, GatewayCode: string) => {
    setId(id);
    setOrderType(GatewayCode);
    setIsOpenInfo(true);
  };

  const showModalWithdraw = (id: number, orderState: number, GatewayCode: string) => {
    setFinanceId(id);
    setOrderType(GatewayCode);
    setOrderState(orderState);
    setIsOpenWithdrawal(true);
  };

  const navigate = useNavigate();

  return (
    <>
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.Data?.map(item => ({ key: item.Id, ...item }))}
        columns={[
          {
            dataIndex: 'Code',
            title: i18n.t('order'),
            width: 250,
            fixed: 'left'
          },
          {
            dataIndex: 'MemberAccount',
            title: i18n.t('memberAccount'),
            width: 160,
            fixed: 'left'
          },
          {
            dataIndex: 'MemberLevelName',
            title: i18n.t('memberLevel'),
            width: 120
          },
          {
            dataIndex: 'MemberGroupName',
            title: i18n.t('memberGroup'),
            width: 120
          },
          {
            dataIndex: 'UpMemberAccount',
            title: `${i18n.t('recommendedMembers')} / ${i18n.t('agent')}`,
            width: 200,
            render: (val, record: any) => (
              <>
                <span>{val || '-'}</span>
                <span> / </span>
                <span>{record.AGAccount || '-'}</span>
              </>
            )
          },
          {
            dataIndex: 'BeforeAmount',
            title: i18n.t('previousBalance'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            // 提款金額
            dataIndex: 'Amount',
            title: i18n.t('withdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.toFormatNumber(val)
          },
          {
            // 實際出款
            dataIndex: 'Amount',
            title: i18n.t('actualWithdrawalAmount'),
            align: 'right',
            width: 200,
            render: (val, { Status }) => Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'AfterAmount',
            title: i18n.t('BalanceAfterModification'),
            align: 'right',
            width: 200,
            render: (val, { Status }) =>
              Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'HandlingFee',
            title: i18n.t('paymentProcessingFee'),
            align: 'right',
            width: 200,
            render: (val, { Status }) => Status === WITHDRAW_STATE.approvaled ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 100,
            render: (val, { GatewayCode }) =>
              GatewayCode === GATEWAY_CODE_WITHDRAW.線下虛擬 || GatewayCode === GATEWAY_CODE_WITHDRAW.三方虛擬
                ? common.toFormatNumber(val || 0, 2 ) : '-'
          },
          {
            dataIndex: 'CreateDate',
            title: i18n.t('createTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'AuditedDate',
            title: i18n.t('auditTime'),
            width: 150,
            render: (val) => timeS2L(val)
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            width: 150,
            fixed: 'right',
            render: (val, { FinanceAccount, ServiceAccount, AuditorAccount, ApproverAccount, GatewayCode }) => (
              <>
                {/* 狀態 */}
                {
                  <div className={common.numColor04(val)}>{i18n.t(WITHDRAW_STATE[val])}</div>
                }
                {/* 誰做的 */}
                {
                  val === WITHDRAW_STATE.pendingSupervisorReview &&
                  <>{i18n.t('from')} {ServiceAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.pendingFinancialReview &&
                  <>{i18n.t('from')} {AuditorAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.approvaled && (GatewayCode === GATEWAY_CODE_WITHDRAW.三方銀行 || GatewayCode === GATEWAY_CODE_WITHDRAW.三方虛擬) &&
                  <>{i18n.t('from')} {ApproverAccount || 'System'} {i18n.t('approvaled')}</>
                }
                {(
                  val === WITHDRAW_STATE.approvaled && (GatewayCode !== GATEWAY_CODE_WITHDRAW.三方銀行 && GatewayCode !== GATEWAY_CODE_WITHDRAW.三方虛擬) ||
                  val === WITHDRAW_STATE.processing ||
                  val === WITHDRAW_STATE.paymentTerminalWaiting ||
                  val === WITHDRAW_STATE.financialReview
                ) &&
                  <>{i18n.t('from')} {ApproverAccount || FinanceAccount || 'System'} {i18n.t('approvaled')}</>
                }
                {
                  val === WITHDRAW_STATE.reject &&
                  <>{i18n.t('from')} {ApproverAccount || ServiceAccount || AuditorAccount || FinanceAccount || 'System'} {i18n.t('operation')}</>
                }
                {
                  val === WITHDRAW_STATE.fail &&
                  <>{i18n.t('from')} {'System'} {i18n.t('operation')}</>
                }
              </>
            )
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 200,
            render: (_, { Status, key, FinanceAccountId, GatewayCode, MemberAccount }) => (
              <>
                {(
                  (Status === WITHDRAW_STATE.pendingServiceReview && $p('20104')) ||
                  (Status === WITHDRAW_STATE.pendingSupervisorReview && $p('20106'))) &&
                  <Button type="link" onClick={() => showModalConfirm(key, Status)}>
                    {i18n.t('audit')}
                  </Button>
                }
                {(
                  (Status === WITHDRAW_STATE.pendingFinancialReview && $p('20108')) ||
                  (Status === WITHDRAW_STATE.financialReview && FinanceAccountId === info.Id)) &&
                  <Button type="link" onClick={() => showModalWithdraw(key, Status, GatewayCode)}>
                    {i18n.t('withdraw')}
                  </Button>
                }
                {(
                  (Status === WITHDRAW_STATE.pendingServiceReview && !$p('20104')) ||
                  (Status === WITHDRAW_STATE.pendingSupervisorReview && !$p('20106')) ||
                  (Status === WITHDRAW_STATE.pendingFinancialReview && !$p('20108')) ||
                  Status === WITHDRAW_STATE.approvaled ||
                  Status === WITHDRAW_STATE.reject ||
                  Status === WITHDRAW_STATE.processing ||
                  Status === WITHDRAW_STATE.fail ||
                  Status === WITHDRAW_STATE.paymentTerminalWaiting ||
                  (Status === WITHDRAW_STATE.financialReview && FinanceAccountId !== info.Id)) &&
                  <Button type="link" onClick={() => showModalDetail(key, GatewayCode)}>
                    {i18n.t('detail')}
                  </Button>
                }

                {$p('10701') && <Button type="link" onClick={() => navigate(`/member/account/${MemberAccount}`)}>{i18n.t('accountRecords')}</Button>}
                {$p('60101') && <Button type="link" onClick={() => navigate(`/report/${MemberAccount}`)}>{i18n.t('gameRecord')}</Button>}
              </>
            ),
          },
        ]}
        pagination={false}
      />

      {/* 前置審核 */}
      <PopupWithdrawAudit isOpen={isOpenAudit} close={() => setIsOpenAudit(false)} id={id} mutate={mutate} orderState={orderState} />
      {/* 財務出款審核 */}
      <PopupWithdrawal isOpen={isOpenWithdrawal} close={() => setIsOpenWithdrawal(false)} id={financeId} mutate={mutate} orderState={orderState} orderType={orderType} setErrorPopup={setErrorPopup} errorPopup={errorPopup} />
      {/* 明細 */}
      <PopupWithdrawInfo isOpen={isOpenInfo} close={() => setIsOpenInfo(false)} id={id} orderType={orderType} mutate={mutate} />
      {/* 鎖單錯誤訊息視窗 */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </>
  )
}
// 會員前置審核
export const PopupWithdrawAudit = ({ isOpen, close, id, mutate, orderState }: any) => {
  const [form] = useForm();
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/one',
    params: { Id: id },
    allow: !!id
  })

  useEffect(() => {
    if (isOpen) {
      RefreshInfo();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data?.Data.AuditorAccessLogRemark || data?.Data.ServiceAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const handlePass = () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.pendingServiceReview:
        $post({
          url: 'api/withdrawticket/member/service/resolve',
          send: {
            Id: id,
            Remark: bnRemarks,
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            if (response.Message === 'TICKET_STATUS_ILLEGAL') {
              mutate();
              onClose();
            }
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
      case WITHDRAW_STATE.pendingSupervisorReview:
        $post({
          url: 'api/withdrawticket/member/auditor/resolve',
          send: {
            Id: id,
            Remark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            if (response.Message === 'TICKET_STATUS_ILLEGAL') {
              mutate();
              onClose();
            }
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
    }
  }

  const handleReject = () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    switch (orderState) {
      case WITHDRAW_STATE.pendingServiceReview:
        $post({
          url: 'api/withdrawticket/member/service/reject',
          send: {
            Id: id,
            FnRemark: fnRemarks,
            BnRemark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            if (response.Message === 'TICKET_STATUS_ILLEGAL') {
              mutate();
              onClose();
            }
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
      case WITHDRAW_STATE.pendingSupervisorReview:
        $post({
          url: 'api/withdrawticket/member/auditor/reject',
          send: {
            Id: id,
            FnRemark: fnRemarks,
            BnRemark: bnRemarks
          },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
            onClose();
          },
          fail: (response: any) => {
            if (response.Message === 'TICKET_STATUS_ILLEGAL') {
              mutate();
              onClose();
            }
          },
          resCode: RESPONSE_CODE_WITHDRAW
        })
        break;
    }
  }

  const onClose = () => {
    close();
    form.resetFields();
  }

  return (
    <Modal
      title={i18n.t('audit')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      width={900}
      footer={false}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form form={form} onFinish={handleReject}>
          {/* 第一段 */}
          <Descriptions
            className="pb-1"
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agentAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.MemberAccount}</Col>
                <Col><CopyButton text={data?.Data?.MemberAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberLevel')}>
              {data?.Data?.LevelName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberGroup')}>
              {data?.Data?.MemberGroupName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agent')}>
              {data?.Data?.AgentAccount}
            </Descriptions.Item>
            {/* 提款金額 */}
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                <>{data?.Data?.ServiceAccount}</>}
              {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                <>{data?.Data?.AuditorAccount}</>}
              {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                <>{data?.Data?.FinanceAccount}</>}
              {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                <>{data?.Data?.FinanceAccount}</>}
              {data?.Data?.Status === WITHDRAW_STATE.reject &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)
              }
              {data?.Data?.Status === WITHDRAW_STATE.fail &&
                (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{timeS2L(data?.Data?.CreateDate)}</Descriptions.Item>
          </Descriptions>
          <Descriptions
            bordered
            size='small'
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="fnRemarks" formRules={[{
                required: true, message: `${i18n.t('rejectionRequiresFrontendRemark')}`
              }]} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="bnRemarks" />
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[12, 12]} align="middle" justify="center"
            className="mt-1">
            <Col>
              <Button type="primary" danger htmlType="submit">
                {i18n.t('reject')}
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={handlePass}>{i18n.t('approvaled')}</Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal >
  )
}
// 會員財務提款審核
export const PopupWithdrawal = ({ isOpen, close, id, mutate, orderType, setErrorPopup, errorPopup }: any) => {
  const { data: $s } = useSite();
  const [form] = useForm();
  const [submitType, setSubmitType] = useState(0);
  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [isSelected, setIsSelected] = useState(true);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/finance/one',
    params: { Id: id },
    allow: !!id
  });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo();
    }
  }, [isOpen]);

  useEffect(() => {
    if (data?.Data.Message === 'ANOTHER_FINANCE_IS_HANDLING') {
      close();
      mutate();
      setErrorPopup(true);
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data?.Data.Crypto,
        CryptoType: data?.Data.CryptoType,
      })
    }
  }, [data]);
  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        bnRemarks: data?.Data.AuditorAccessLogRemark
      })
    }
  }, [data, isOpen]);

  const [range, setRange] = useState({
    min: 0,
    max: 0,
    fee: 0,
    rate: 0
  });
  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = $get({
    url: 'api/withdrawmerchant/bank/resolve/list',
    params: {
      IsOffline: withdrawalChannel,
      TicketId: id
    },
    allow: !!id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = $get({
    url: 'api/withdrawmerchant/crypto/resolve/list',
    params: {
      Crypto: cryptoParams.Crypto,
      CryptoType: cryptoParams.CryptoType,
      TicketId: id,
    },
    allow: !!cryptoParams.Crypto && !!cryptoParams.CryptoType && !!id
  })

  const handleSelect = (selected: number) => {
    setIsSelected(false);
    switch (selected) {
      case GATEWAY_TYPE.thirdParty:
        setWithdrawalChannel(0);
        break;
      case GATEWAY_TYPE.transfer:
        setWithdrawalChannel(1);
        break;
    }
    form.setFieldsValue({
      withdrawalChannel: null
    })
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    })
  }

  const onSelectChannel = (selected: number) => {
    Withdrawmerchant?.Data.filter((item: { Id: number }) => (
      item.Id === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const onSelectCrypto = (selected: number) => {
    Crypto?.Data.filter((item: { WithdrawMerchantId: number }) => (
      item.WithdrawMerchantId === selected
    )).map((item: { Minimum: number, Maximum: number, HandlingFee: number, Rate: number }) => (
      setRange({ min: item.Minimum, max: item.Maximum, fee: item.HandlingFee, rate: item.Rate })
    ))
  }

  const handlePass = () => {
    const { withdrawalType, withdrawalChannel, bnRemarks, crypto } = form.getFieldsValue();
    if (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬) {
      if (!crypto) return setSubmitType(AGENT_ADJUST_STATE.approvaled);
    } else {
      if (!withdrawalType && !withdrawalChannel) return setSubmitType(AGENT_ADJUST_STATE.approvaled);
    }
    if (data?.Data.Amount > range.max && range.max) {
      return message.error(i18n.t('withdrawalAmountExceedsMaximumLimit'));
    }
    if (data?.Data.Amount < range.min) {
      return message.error(i18n.t('withdrawalAmountBelowMinimumLimit'));
    }
    // 代理沒有擋!withdrawalChannel
    if (!(orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬) && !withdrawalChannel) {
      return ''
    }
    $post({
      url: 'api/withdrawticket/member/finance/resolve',
      send: {
        Id: id,
        WithdrawMerchantId: withdrawalChannel || crypto,
        BnRemark: bnRemarks,
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      },
      fail: (response: any) => {
        if (response.Message === 'TICKET_STATUS_ILLEGAL') {
          mutate();
          onClose();
        }
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const handleReject = () => {
    const { fnRemarks, bnRemarks } = form.getFieldsValue();
    if (!fnRemarks) return setSubmitType(AGENT_ADJUST_STATE.reject);
    $post({
      url: 'api/withdrawticket/member/finance/reject',
      send: {
        Id: id,
        FnRemark: fnRemarks,
        BnRemark: bnRemarks
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        onClose();
      },
      fail: (response: any) => {
        if (response.Message === 'TICKET_STATUS_ILLEGAL') {
          mutate();
          onClose();
        }
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const onClose = () => {
    close();
    setIsSelected(true);
    form.setFieldsValue({
      bnRemarks: data?.Data.AuditorAccessLogRemark,
      fnRemarks: '',
      crypto: null,
      withdrawalType: null,
      withdrawalChannel: null,
    });
    // 清空 rules
    form.setFields([
      {
        name: 'fnRemarks',
        value: '',
        errors: [],
      },
    ]);
    setRange({
      min: 0,
      max: 0,
      fee: 0,
      rate: 0,
    });
  }

  const isVirtual = (
    orderType === GATEWAY_CODE_WITHDRAW.線下虛擬 ||
    orderType === GATEWAY_CODE_WITHDRAW.三方虛擬
  );

  return (
    <Modal
      title={i18n.t('withdraw')}
      open={isOpen}
      onCancel={onClose}
      centered={true}
      footer={false}
      width={900}
      forceRender
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.MemberAccount}</Col>
                <Col><CopyButton text={data?.Data?.MemberAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberLevel')}>
              {data?.Data?.LevelName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberGroup')}>
              {data?.Data?.MemberGroupName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agent')}>
              {data?.Data?.AgentAccount}
            </Descriptions.Item>
            {/* 提款金額 */}
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>

            {
              (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬)
                ?
                <Descriptions.Item label={i18n.t('currency')}>
                  {data?.Data?.Crypto}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalType')}>
                  <Form.Item name="withdrawalType" rules={[{
                    required: submitType === AGENT_ADJUST_STATE.approvaled,
                    message: `${i18n.t('required')}`
                  }]}>
                    <Select
                      onChange={handleSelect}
                      className="w-full"
                      placeholder={i18n.t('pleaseSelect')}
                      options={common.enumToOptions(GATEWAY_TYPE)}
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬)
                ?
                <Descriptions.Item label={i18n.t('networkType')}>
                  {data?.Data?.CryptoType}
                </Descriptions.Item>
                :
                <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                  <Form.Item name="withdrawalChannel" rules={[{
                    required: submitType === AGENT_ADJUST_STATE.approvaled,
                    message: `${i18n.t('required')}`
                  }]}>
                    <Select
                      loading={WithdrawmerchantLoading}
                      disabled={isSelected}
                      className="w-full"
                      onChange={onSelectChannel}
                      placeholder={i18n.t('pleaseSelect')}
                      options={
                        Withdrawmerchant?.Data.map((item: { Id: number, CustomName: string }) => (
                          { value: item.Id, label: `${item.CustomName}` }
                        )) || []
                      }
                    />
                  </Form.Item>
                </Descriptions.Item>
            }
            {
              (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬) &&
              <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('userAddress')}`}>
                <Form.Item name="crypto" rules={[{
                  required: submitType === AGENT_ADJUST_STATE.approvaled,
                  message: `${i18n.t('required')}`
                }]}>
                  <Select
                    loading={CryptoLoading}
                    style={{ maxWidth: 200 }}
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    onChange={onSelectCrypto}
                    options={
                      Crypto?.Data.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                        {
                          value: item.WithdrawMerchantId,
                          label: `${item.CustomName}-${item.CryptoAddress}`
                        }
                      )) || []
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
            }
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber((data?.Data?.Amount * range.rate) + range.fee)}／{
                isVirtual ? common.toFormatNumber(data?.Data?.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
            {
              (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬) &&
              <Descriptions.Item label={`≈ ${data?.Data?.Crypto}`}>
                {common.toFormatNumber(data?.Data?.Amount / data?.Data?.ExchangeRate, 2, 'floor')}
              </Descriptions.Item>
            }
            <Descriptions.Item label={i18n.t('withdrawalAmountLimit')}>
              {common.toFormatNumber(range.min)} - {range.max ? common.toFormatNumber(range.max) : i18n.t('unlimited')}
            </Descriptions.Item>
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {
              (orderType === GATEWAY_CODE_WITHDRAW.三方虛擬 || orderType === GATEWAY_CODE_WITHDRAW.線下虛擬)
                ? <>
                  <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.CryptoAddress}</Col>
                      <Col><CopyButton text={data?.Data?.CryptoAddress} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankAccountName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('QR Code')}>
                    {
                      data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]
                        ? <Image src={data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]} height={100} />
                        : '-'
                    }
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('bankName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.BankName}</Col>
                      <Col><CopyButton text={data?.Data?.BankName} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('accountNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  {
                    $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></> :
                      <>
                        <Descriptions.Item label={i18n.t('openingBranch')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.Branch || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.Branch} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('bankCounty')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.State || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.State} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('city')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.City || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.City} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('phoneNumber')}>
                          <Row align="middle" justify="space-between">
                            <Col>{`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`}</Col>
                            <Col><CopyButton text={`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`} /></Col>
                          </Row>
                        </Descriptions.Item>
                      </>
                  }
                </>
            }
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {
                <>
                  {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                    <>{data?.Data?.ServiceAccount}</>}
                  {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                    <>{data?.Data?.AuditorAccount}</>}
                  {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                    <>{data?.Data?.FinanceAccount}</>}
                  {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                    <>{data?.Data?.FinanceAccount}</>}
                  {data?.Data?.Status === WITHDRAW_STATE.reject &&
                    (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)
                  }
                  {data?.Data?.Status === WITHDRAW_STATE.fail &&
                    (data?.Data?.ServiceAccount || data?.Data?.AuditorAccount || data?.Data?.FinanceAccount)
                  }
                </>
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              {<div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{timeS2L(data?.Data?.CreateDate)}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size="small"
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="fnRemarks" formRules={[{
                required: submitType === AGENT_ADJUST_STATE.reject,
                message: `${i18n.t('rejectionRequiresFrontendRemark')}`
              }]} />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')} contentStyle={{ height: 100 }}>
              <LayoutTextArea formName="bnRemarks" />
            </Descriptions.Item>
          </Descriptions>
          <Row gutter={[12, 12]} align="middle" justify="center" className="mt-1">
            <Col>
              <Button type="primary" danger htmlType="submit" onClick={handleReject}>{i18n.t('reject')}</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit" onClick={handlePass}>{i18n.t('approvaled')}</Button>
            </Col>
          </Row>
        </Spin>
      </Form>
      {/* Modal */}
      <PopupOrderLock isOpen={errorPopup} close={() => setErrorPopup(false)} />
    </Modal>
  )
}
// 會員提款明細
export const PopupWithdrawInfo = ({ isOpen, close, id, orderType, mutate }: any) => {
  const [form] = useForm();
  const { info, permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const { data, isValidating, mutate: RefreshInfo } = $get({
    url: 'api/withdrawticket/one',
    params: { Id: id },
    allow: !!id
  });

  useEffect(() => {
    if (isOpen) {
      RefreshInfo()
    }
  }, [isOpen]);

  const [withdrawalChannel, setWithdrawalChannel] = useState<number>(0);
  const [cryptoParams, setCryptoParams] = useState({
    Crypto: null,
    CryptoType: null,
  });

  useEffect(() => {
    if (data) {
      switch (data?.Data?.WithdrawMerchantGatewayCode) {
        case GATEWAY_CODE_WITHDRAW.三方銀行:
        case GATEWAY_CODE_WITHDRAW.三方虛擬:
          setWithdrawalChannel(0)
          break;
        case GATEWAY_CODE_WITHDRAW.線下銀行:
        case GATEWAY_CODE_WITHDRAW.線下虛擬:
          setWithdrawalChannel(1)
          break;
      }
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setCryptoParams({
        Crypto: data?.Data.Crypto,
        CryptoType: data?.Data.CryptoType,
      })
    }
  }, [data]);
  useEffect(() => {
    if (data) {
      if (data?.Data?.Status === WITHDRAW_STATE.reject) {
        form.setFieldsValue({
          crypto: null,
          withdrawalChannel: null,
          withdrawalType: null
        })
      } else {
        form.setFieldsValue({
          crypto: data?.Data?.WithdrawMerchantId,
          withdrawalChannel: data?.Data?.WithdrawMerchantName,
          withdrawalType: data?.Data?.WithdrawMerchantGatewayCode
        })
      }
    }
  }, [data])

  const {
    data: Withdrawmerchant,
    isValidating: WithdrawmerchantLoading,
  } = $get({
    url: 'api/withdrawmerchant/bank/resolve/list',
    params: {
      IsOffline: withdrawalChannel,
      TicketId: id
    },
    allow: !!id
  });
  const {
    data: Crypto,
    isValidating: CryptoLoading,
  } = $get({
    url: 'api/withdrawmerchant/crypto/resolve/list',
    params: {
      Crypto: cryptoParams.Crypto,
      CryptoType: cryptoParams.CryptoType,
      TicketId: id,
    },
    allow: !!cryptoParams.Crypto && !!cryptoParams.CryptoType && !!id
  })

  const onReject = () => {
    $post({
      url: 'api/withdrawticket/member/finance/reject',
      send: {
        Id: data?.Data.Id,
        FnRemark: data?.Data.FnRemark,
        BnRemark: data?.Data.FinanceAccessLogRemark
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        close();
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const onForcePass = () => {
    $post({
      url: 'api/withdrawticket/member/finance/force/resolve',
      send: { Id: data?.Data.Id },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        mutate();
        close();
      },
      resCode: RESPONSE_CODE_WITHDRAW
    })
  }

  const isVirtual = (
    data?.Data?.WithdrawMerchantGatewayCode === GATEWAY_CODE_WITHDRAW.線下虛擬 ||
    data?.Data?.WithdrawMerchantGatewayCode === GATEWAY_CODE_WITHDRAW.三方虛擬
  );

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={close}
      centered={true}
      width={900}
      footer={
        <Row align="middle" justify="center" gutter={[12, 12]}>
          {
            data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting && $p('20108')
              ? <>
                <Col>
                  <Button type="primary" danger onClick={onReject}>{i18n.t('forceReject')}</Button>
                </Col>
                <Col>
                  <Button type="primary" onClick={onForcePass}>{i18n.t('forceApproval')}</Button>
                </Col>
              </>
              : <Col>
                <Button type="primary" onClick={close}>{i18n.t('close')}</Button>
              </Col>
          }
          {
            data?.Data?.Status === WITHDRAW_STATE.fail &&
            (data?.Data?.FinanceAccount === info.Account) &&
            <Col>
              <Button type="primary" danger onClick={onReject}>{i18n.t('forceReject')}</Button>
            </Col>
          }
        </Row >
      }
    >
      <Form form={form}>
        <Spin spinning={isValidating}>
          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('order')}>
              {data?.Data?.Code}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberAccount')}>
              <Row align="middle" justify="space-between">
                <Col>{data?.Data?.MemberAccount}</Col>
                <Col><CopyButton text={data?.Data?.MemberAccount} /></Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberLevel')}>
              {data?.Data?.LevelName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('memberGroup')}>
              {data?.Data?.MemberGroupName}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('agent')}>
              {data?.Data?.AgentAccount}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('withdrawalAmount')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('totalActualWithdrawal')}>
              {common.toFormatNumber(data?.Data?.Amount)}
            </Descriptions.Item>

            {
              (orderType === GATEWAY_CODE_WITHDRAW.線下虛擬 || orderType === GATEWAY_CODE_WITHDRAW.三方虛擬)
                ?
                <>
                  <Descriptions.Item label={i18n.t('currency')}>
                    {data?.Data?.Crypto}
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('networkType')}>
                    {data?.Data?.CryptoType}
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('withdrawalType')}>
                    <Form.Item name="withdrawalType">
                      <Select
                        disabled
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={[
                          { value: GATEWAY_CODE_WITHDRAW.三方虛擬, label: i18n.t('thirdParty') },
                          { value: GATEWAY_CODE_WITHDRAW.三方銀行, label: i18n.t('thirdParty') },
                          { value: GATEWAY_CODE_WITHDRAW.線下虛擬, label: i18n.t('transfer') },
                          { value: GATEWAY_CODE_WITHDRAW.線下銀行, label: i18n.t('transfer') },
                        ]}
                      />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('withdrawalChannel')}>
                    <Form.Item name="withdrawalChannel">
                      <Select
                        loading={WithdrawmerchantLoading}
                        disabled
                        className="w-full"
                        placeholder={i18n.t('pleaseSelect')}
                        options={
                          Withdrawmerchant?.Data.map((item: { Name: string, CustomName: string }) => (
                            { value: item.Name, label: `${item.CustomName}` }
                          )) || []
                        }
                      />
                    </Form.Item>
                  </Descriptions.Item>
                </>
            }
            {
              (orderType === GATEWAY_CODE_WITHDRAW.線下虛擬 || orderType === GATEWAY_CODE_WITHDRAW.三方虛擬) &&
              <Descriptions.Item label={`${i18n.t('displayName')}-${i18n.t('userAddress')}`}>
                <Form.Item name="crypto">
                  <Select
                    disabled
                    loading={CryptoLoading}
                    style={{ maxWidth: 200 }}
                    className="w-full"
                    placeholder={i18n.t('pleaseSelect')}
                    options={
                      Crypto?.Data.map((item: { WithdrawMerchantId: number, CustomName: string, CryptoAddress: string }) => (
                        {
                          value: item.WithdrawMerchantId,
                          label: `${item.CustomName}-${item.CryptoAddress}`
                        }
                      )) || []
                    }
                  />
                </Form.Item>
              </Descriptions.Item>
            }
            {/* 手續費 */}
            <Descriptions.Item label={`${i18n.t('paymentProcessingFee')}／${i18n.t('exchangeRate')}`}>
              {common.toFormatNumber(data?.Data?.HandlingFee)}／{
                isVirtual ? common.toFormatNumber(data?.Data?.ExchangeRate || 0, 2) : '-'}
            </Descriptions.Item>
          </Descriptions>

          {/* 第二區 */}
          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            {
              (orderType === GATEWAY_CODE_WITHDRAW.線下虛擬 || orderType === GATEWAY_CODE_WITHDRAW.三方虛擬)
                ? <>
                  <Descriptions.Item label={i18n.t('withdrawalAddress')} span={2}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.CryptoAddress}</Col>
                      <Col><CopyButton text={data?.Data?.CryptoAddress} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('bankAccountName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('QR Code')}>
                    {
                      data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]
                        ? <Image src={data?.Data.Photo && JSON.parse(data?.Data?.Photo)[0]} height={100} />
                        : '-'
                    }
                  </Descriptions.Item>
                </>
                : <>
                  <Descriptions.Item label={i18n.t('bankName')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.BankName}</Col>
                      <Col><CopyButton text={data?.Data?.BankName} /></Col>
                    </Row>
                  </Descriptions.Item>
                  <Descriptions.Item label={i18n.t('accountNumber')}>
                    <Row align="middle" justify="space-between">
                      <Col>{data?.Data?.Account || '-'}</Col>
                      <Col><CopyButton text={data?.Data?.Account} /></Col>
                    </Row>
                  </Descriptions.Item>
                  {
                    $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? <></>
                      : <>
                        <Descriptions.Item label={i18n.t('branchName')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.Branch || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.Branch} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('bankCounty')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.State || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.State} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('city')}>
                          <Row align="middle" justify="space-between">
                            <Col>{data?.Data?.City || '-'}</Col>
                            <Col><CopyButton text={data?.Data?.City} /></Col>
                          </Row>
                        </Descriptions.Item>
                        <Descriptions.Item label={i18n.t('phoneNumber')}>
                          <Row align="middle" justify="space-between">
                            <Col>{`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`}</Col>
                            <Col><CopyButton text={`${data?.Data?.PhonePrefix}-${data?.Data?.PhoneNumber}`} /></Col>
                          </Row>
                        </Descriptions.Item>
                      </>
                  }
                </>
            }
          </Descriptions>

          <Descriptions
            className="pb-1"
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('auditor')}>
              {data?.Data?.Status === WITHDRAW_STATE.pendingSupervisorReview &&
                <>{data?.Data?.ServiceAccount || 'System'}</>}
              {data?.Data?.Status === WITHDRAW_STATE.pendingFinancialReview &&
                <>{data?.Data?.AuditorAccount || 'System'}</>}
              {data?.Data?.Status === WITHDRAW_STATE.approvaled &&
                <>{data?.Data?.ApproverAccount || data?.Data?.FinanceAccount || 'System'}</>}
              {data?.Data?.Status === WITHDRAW_STATE.paymentTerminalWaiting &&
                <>{data?.Data?.FinanceAccount || 'System'}</>}
              {data?.Data?.Status === WITHDRAW_STATE.reject &&
                (
                  <>{data?.Data?.ServiceAccount || 'System'}</> ||
                  <>{data?.Data?.AuditorAccount || 'System'}</> ||
                  <>{data?.Data?.FinanceAccount || 'System'}</>
                )
              }
              {data?.Data?.Status === WITHDRAW_STATE.fail &&
                (
                  <>{data?.Data?.ServiceAccount || 'System'}</> ||
                  <>{data?.Data?.AuditorAccount || 'System'}</> ||
                  <>{data?.Data?.FinanceAccount || 'System'}</>
                )
              }
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('status')}>
              <div className={common.numColor04(data?.Data?.Status)}>{i18n.t(WITHDRAW_STATE[data?.Data?.Status])}</div>
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('createTime')}>{timeS2L(data?.Data?.CreateDate)}</Descriptions.Item>
          </Descriptions>

          <Descriptions
            bordered
            size={'small'}
            column={2}
            labelStyle={{ width: '18%' }}
            contentStyle={{ width: '32%' }}
          >
            <Descriptions.Item label={i18n.t('frontDeskRemarks')}>
              <Input value={data?.Data?.FnRemark} placeholder={`${i18n.t('inputData')}`} disabled />
            </Descriptions.Item>
            <Descriptions.Item label={i18n.t('backOfficeRemarks')}>
              <Input value={data?.Data && data?.Data.FinanceAccessLogRemark} placeholder={`${i18n.t('inputData')}`} disabled />
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </Form>
    </Modal >
  )
}
// 鎖單錯誤訊息視窗
export const PopupOrderLock = ({ isOpen, close }: any) => {

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" onClick={close}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('orderCurrentlyLocked')}</div>
      </Space>
    </Modal>
  )
}

// 轉帳出入款管理: 銀行卡列表
export const ViewTransferBank = ({ data, mutate, type }: any) => {
  const { permissions: $p } = useAccount();
  const { data: $s } = useSite();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { text } = useSelector((state: RootType) => state.Search);
  const [isModalOpenEdit, setIsModalOpenEdit] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpenDepositAmountSetting, setIsModalOpenDepositAmountSetting] = useState(false);
  const [isModalOpenWithdrawalsAmountSetting, setIsModalOpenWithdrawalsAmountSetting] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});

  const submitDelete = () => {
    switch (type) {
      case TRANSFER_TYPE.deposit:
        $post({
          url: 'api/depositmerchant/offline/delete',
          send: { Id: currentCard.Id },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
          },
          resCode: RESPONSE_CODE_ADD_DEPOSIT
        })
        break;
      case TRANSFER_TYPE.withdrawal:
        $post({
          url: 'api/withdrawmerchant/offline/delete',
          send: { Id: currentCard.Id },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
          },
          resCode: RESPONSE_CODE_ADD_DEPOSIT
        })
        break;
    }
  }

  return (
    <>
      {/* 新增 */}
      {
        $p('20702') && type === TRANSFER_TYPE.deposit &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setIsModalOpenEdit(true);
        }}>{i18n.t('add')}</Button>
      }
      {
        $p('20402') && type === TRANSFER_TYPE.withdrawal &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setIsModalOpenEdit(true);
        }}>{i18n.t('add')}</Button>
      }
      {/* 快捷設定 */}
      {
        type === TRANSFER_TYPE.deposit &&
        <Button className={'mb-1 ml-1'} onClick={() => {
          setIsModalOpenDepositAmountSetting(true);
        }}>{i18n.t('depositAmountQuickSetting')}</Button>
      }
      {
        type === TRANSFER_TYPE.withdrawal &&
        <Button className={'mb-1 ml-1'} onClick={() => {
          setIsModalOpenWithdrawalsAmountSetting(true);
        }}>{i18n.t('withdrawalsAmountQuickSetting')}</Button>
      }

      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.map((item: TransferDepositType) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('sort'),
            fixed: 'left',
            align: "center",
            width: 50
          },
          {
            dataIndex: 'BankName',
            title: i18n.t('bankName'),
            fixed: 'left',
            width: 180
          },
          {
            dataIndex: 'CustomName',
            title: i18n.t('displayName'),
            width: 180,
          },
          {
            dataIndex: 'Name',
            title: i18n.t('accountName'),
            width: 130,
          },
          {
            dataIndex: 'Account',
            title: i18n.t('accountNumber'),
            width: 160,
          },
          {
            dataIndex: 'Branch',
            title: i18n.t('branchName'),
            width: 130,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
          },
          {
            dataIndex: 'PhoneNumber',
            title: i18n.t('phoneNumber'),
            width: 160,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            render: (val, { PhonePrefix }: any) => val ? `${PhonePrefix}-${val}` : '-'
          },
          {
            dataIndex: 'State',
            title: i18n.t('bankCounty'),
            width: 130,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            render: (val) => val || '-'
          },
          {
            dataIndex: 'City',
            title: i18n.t('city'),
            width: 130,
            hidden: $s && ($s.Currency === 'VND' || $s.Currency === 'kVND'),
            render: (val) => val || '-'
          },
          {
            dataIndex: 'Minimum',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('minDepositAmount') : i18n.t('minWithdrawingAmount'),
            align: 'right',
            width: 180,
            render: (val) => common.isNumber(val) ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'Maximum',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('maxDepositAmount') : i18n.t('maxWithdrawingAmount'),
            align: 'right',
            width: 180,
            render: (val) => common.isNumber(val) ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'Rate',
            title: i18n.t('paymentFeePercentageTransactionFee'),
            align: 'right',
            width: 200,
            render: (val, record: any) => (
              <>{`${common.convertedToPercentage01(val)} %`} / {common.toFormatNumber(record.HandlingFee, 2)}</>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            align: "center",
            width: 100,
            render: (val) => <div className={colorChange(val)}>{i18n.t(TRANSFER_SETTING_STATUS[val])}</div>
          },
          {
            dataIndex: 'QR_Code',
            title: i18n.t('QRcode'),
            className: type === TRANSFER_TYPE.deposit ? '' : 'hide',
            align: 'center',
            width: 100,
            render: (val) => val ? <Image src={val} width={30} height={30} /> : '-'
          },
          {
            dataIndex: 'TotalCount',
            title: i18n.t('totalTransactionsToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p('20901') && type === TRANSFER_TYPE.deposit
                    ? <Button type="link" onClick={() => {
                      dispatch(setSearchHistory({
                        text: {
                          bankName: record.BankName,
                          bankDisplayName: record.CustomName
                        }
                      }))
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val, 0)}
                    </Button>
                    : (
                      $p('20601') && type === TRANSFER_TYPE.withdrawal
                        ? <Button type="link" onClick={() => {
                          dispatch(setSearchHistory({
                            text: {
                              bankName: record.BankName,
                              bankDisplayName: record.CustomName
                            }
                          }))
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val, 0)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'TotalAmount',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p('20901') && type === TRANSFER_TYPE.deposit
                    ? <Button type="link" onClick={() => {
                      dispatch(setSearchHistory({
                        text: {
                          bankName: record.BankName,
                          bankDisplayName: record.CustomName
                        }
                      }))
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val)}
                    </Button>
                    : (
                      $p('20601') && type === TRANSFER_TYPE.withdrawal
                        ? <Button type="link" onClick={() => {
                          dispatch(setSearchHistory({
                            text: {
                              bankName: record.BankName,
                              bankDisplayName: record.CustomName
                            }
                          }))
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val)}
                        </Button>
                        : common.toFormatNumber(val)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'FnDescription',
            title: i18n.t('frontDeskRemarks'),
            ellipsis: true,
            width: 200,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'BnDescription',
            title: i18n.t('backOfficeRemarks'),
            ellipsis: true,
            width: 200,
            render: (val) => val || '-'
          },
          {
            dataIndex: 'comment',
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record: any) => (
              <>
                {
                  $p('20703') && type === TRANSFER_TYPE.deposit &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p('20403') && type === TRANSFER_TYPE.withdrawal &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenEdit(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p('20705') && type === TRANSFER_TYPE.deposit &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
                {
                  $p('20404') && type === TRANSFER_TYPE.withdrawal &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <DepositAmountSetting isOpen={isModalOpenDepositAmountSetting} close={() => setIsModalOpenDepositAmountSetting(false)} />
      <WithdrawalsAmountSetting isOpen={isModalOpenWithdrawalsAmountSetting} close={() => setIsModalOpenWithdrawalsAmountSetting(false)} />
      <PopupBank isOpen={isModalOpenEdit} close={() => setIsModalOpenEdit(false)} mutate={mutate} id={currentCard.Id} type={type} currentCard={currentCard} />
      <InquiryWindow isOpen={isModalOpenDelete} close={() => setIsModalOpenDelete(false)}
        msg={`${i18n.t('confirmDeletion')} ${currentCard.CustomName} ${i18n.t('isData')}`} action={submitDelete} />
    </>
  )
}

// 轉帳出入款: 入款/出款 (站長銀行卡)新增編輯
// 聖經新增編輯
export const PopupBank = ({ isOpen, close, mutate, type, id, currentCard }: any) => {
  const { data: $s, bankCode2Name } = useSite();
  // 銀行名稱列表
  const { data: _getBankNameList } = $get({
    url: `api/bank/${$s?.Currency}/list`,
    allow: !!($s?.Currency)
  })

  const mode = !id ? MODE.add : MODE.edit;

  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const { data: DepositBankInfo, isValidating: depositValidating, mutate: mutateDeposit } = $get({
    url: 'api/depositmerchant/offline/one',
    params: { Id: id },
    allow: type === TRANSFER_TYPE.deposit && !!id
  })
  const { data: WithdrawalBankInfo, isValidating: withdrawalValidating, mutate: mutateWithdrawal } = $get({
    url: 'api/withdrawmerchant/offline/one',
    params: { Id: id },
    allow: type === TRANSFER_TYPE.withdrawal && !!id
  });

  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen]);

  useEffect(() => {
    if ((DepositBankInfo || WithdrawalBankInfo) && isOpen) {
      const info = DepositBankInfo?.Data || WithdrawalBankInfo?.Data;
      form.setFieldsValue({
        BankCode: info.BankCode,
        CustomName: info.CustomName,
        Name: info.Name,
        Account: info.Account,
        Branch: info.Branch,
        Phone: info.PhoneNumber,
        State: info.State,
        City: info.City,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        Status: info.Status,
        Position: info.Position,
        PhonePrefix: info.PhonePrefix.replace('+', ''),
        FnDescription: info.FnDescription,
        BnDescription: info.BnDescription,
        MerchantId: info.DepositMerchantId || info.WithdrawMerchantId
      });
      info.QR_Code && setImageData([
        {
          uid: info.QR_Code,
          name: info.QR_Code,
          url: info.QR_Code,
        },
      ])
    }
  }, [DepositBankInfo, WithdrawalBankInfo, isOpen]);

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = (formData: any) => {
    const send: any = {
      CustomName: formData.CustomName,
      BankCode: formData.BankCode,
      BankName: bankCode2Name(formData.BankCode),
      Name: formData.Name,
      Account: formData.Account,
      Branch: formData.Branch,
      PhonePrefix: formData.PhonePrefix ? `+${formData.PhonePrefix}` : '',
      Phone: formData.Phone,
      State: formData.State,
      City: formData.City,
      Rate: common.convertedToPercentage02(formData.Rate),
      HandlingFee: formData.HandlingFee,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      Position: formData.Position,
      Status: formData.Status,
      FnDescription: formData.FnDescription,
      BnDescription: formData.BnDescription,
    }
    if (type === TRANSFER_TYPE.deposit) send.QR_Code = imageData[0] ? imageData[0].url : '';
    if (mode === MODE.add) {
      $post({
        url: type === TRANSFER_TYPE.deposit
          ? 'api/depositmerchant/offline/bank/create'
          : 'api/withdrawmerchant/offline/bank/create',
        send,
        success: () => {
          message.success(i18n.t('addSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE_ADD_DEPOSIT
      })
    } else if (mode === MODE.edit) {
      send.Id = id
      send.MerchantId = formData.MerchantId;
      $post({
        url: type === TRANSFER_TYPE.deposit
          ? 'api/depositmerchant/offline/bank/update'
          : 'api/withdrawmerchant/offline/bank/update',
        send,
        success: () => {
          message.success(i18n.t('editSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE_ADD_DEPOSIT
      })
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={mode === MODE.add ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
      forceRender
    >
      <Spin spinning={depositValidating || withdrawalValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          Status: ENABLE.enabled
        }}>
          <Form.Item className="display-none" name="MerchantId" />
          <Row gutter={[12, 12]} className="mb-1">
            <Col span={12}>
              <Form.Item name="BankCode" label={i18n.t('bankName')} rules={[{ required: true }]}>
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    `${(option?.label ?? '')}`.toLowerCase().includes(input.toLowerCase()) ||
                    `${(option?.value ?? '')}`.toLowerCase().includes(input.toLowerCase())
                  }
                  className="w-full"
                  placeholder={`${i18n.t('inputData')}`}
                  options={_getBankNameList?.Data.map((item: any) => {
                    return { value: item.Code, label: i18n.t(item.Name) }
                  })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Name" label={i18n.t('accountName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Account" label={i18n.t('accountNumber')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            {
              $s && ($s.Currency === 'VND' || $s.Currency === 'kVND') ? '' :
                <>
                  <Col span={12}>
                    <Form.Item name="Branch" label={i18n.t('branchName')} rules={[{ required: true }]}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="State" label={i18n.t('bankCounty')} >
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="PhonePrefix" label={i18n.t('countryCode')}
                      rules={common.verify({ point: 7, required: false })}>
                      <Input placeholder={`${i18n.t('inputData')}`} addonBefore={'+'} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="Phone" label={i18n.t('phoneNumber')}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="City" label={i18n.t('city')}>
                      <Input placeholder={`${i18n.t('inputData')}`} />
                    </Form.Item>
                  </Col>
                </>
            }
            <Col span={12}>
              <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} />
              </Form.Item>
            </Col>
            {/* 手續費 */}
            <Col span={12}>
              <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Minimum"
                label={type === TRANSFER_TYPE.deposit ? i18n.t('minDepositAmount') : i18n.t('minWithdrawingAmount')}
                rules={verify({ point: 4, min: 1, isShowCompareNumber: true })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {/* 最高 */}
              <Form.Item
                name="Maximum"
                label={type === TRANSFER_TYPE.deposit ? i18n.t('maxDepositAmount') : i18n.t('maxWithdrawingAmount')}
                rules={verify({ point: 4, zero: false, required: false, max: 999999999 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
                <Select className="w-full" options={common.enumToOptions(ENABLE)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Position" label={i18n.t('sort')}
                rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            {type === TRANSFER_TYPE.deposit && <>
              <Col span={24}>
                <Form.Item name="QRCode" label={i18n.t('qrCode')}>
                  {id !== undefined && currentCard.QR_Code !== "" ?
                    <UploadImage
                      name="QRCode"
                      url={'/upload/images/public/depositmerchant'}
                      preUrl={DepositBankInfo ? DepositBankInfo.Data.QR_Code : ''}
                      imageData={imageData}
                      setImageData={setImageData}
                    /> :
                    <UploadImage
                      name="QRCode"
                      url={'/upload/images/public/depositmerchant'}
                      imageData={imageData}
                      setImageData={setImageData}
                    />}
                </Form.Item>
              </Col>
              <Col span={24}>
                <LayoutTextArea formName="FnDescription" formLabel={i18n.t('frontDeskRemarks')} />
              </Col>
            </>}
            <Col span={24}>
              <LayoutTextArea formName="BnDescription" formLabel={i18n.t('backOfficeRemarks')} />
            </Col>
            <Col span={24} className="mt-1">
              <Row align="middle" justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 虛擬貨幣列表
export const ViewTransferCrypto = ({ data, mutate, type }: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions: $p } = useAccount();
  const [addCrypto, setAddCrypto] = useState(false);
  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const [isModalOpenDepositAmountSetting, setIsModalOpenDepositAmountSetting] = useState(false);
  const [isModalOpenWithdrawalsAmountSetting, setIsModalOpenWithdrawalsAmountSetting] = useState(false);
  const [currentCard, setCurrentCard] = useState<any>({});

  const submitDelete = () => {
    switch (type) {
      case TRANSFER_TYPE.deposit:
        $post({
          url: 'api/depositmerchant/offline/delete',
          send: { Id: currentCard.Id },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
          },
          resCode: RESPONSE_CODE_ADD_DEPOSIT
        })
        break;
      case TRANSFER_TYPE.withdrawal:
        $post({
          url: 'api/withdrawmerchant/offline/delete',
          send: { Id: currentCard.Id },
          success: () => {
            message.success(i18n.t('operationSuccess'));
            mutate();
          },
          resCode: RESPONSE_CODE_ADD_DEPOSIT
        })
        break;
    }
  }

  return (
    <>
      {/* 新增 */}
      {
        $p('20702') && type === TRANSFER_TYPE.deposit &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setAddCrypto(true)
        }}>{i18n.t('add')}</Button>
      }
      {
        $p('20402') && type === TRANSFER_TYPE.withdrawal &&
        <Button type="primary" className={'mb-1'} onClick={() => {
          setCurrentCard({});
          setAddCrypto(true)
        }}>{i18n.t('add')}</Button>
      }
      {/* 快捷設定 */}
      {
        type === TRANSFER_TYPE.deposit &&
        <Button className={'mb-1 ml-1'} onClick={() => {
          setIsModalOpenDepositAmountSetting(true);
        }}>{i18n.t('depositAmountQuickSetting')}</Button>
      }
      {
        type === TRANSFER_TYPE.withdrawal &&
        <Button className={'mb-1 ml-1'} onClick={() => {
          setIsModalOpenWithdrawalsAmountSetting(true);
        }}>{i18n.t('withdrawalsAmountQuickSetting')}</Button>
      }
      <Table
        size="middle"
        scroll={{ x: 1500 }}
        dataSource={data?.map((item: TransferDepositType) => ({ ...item, key: item.Id }))}
        columns={[
          {
            dataIndex: 'Position',
            title: i18n.t('sort'),
            fixed: 'left',
            align: "center",
            width: 50
          },
          {
            dataIndex: 'Crypto',
            title: i18n.t('currency'),
            fixed: 'left',
            width: 100,
          },
          {
            dataIndex: 'CryptoType',
            title: i18n.t('networkType'),
            fixed: 'left',
            width: 150,
          },
          {
            dataIndex: 'CustomName',
            title: i18n.t('displayName'),
            fixed: 'left',
            width: 200,
          },
          {
            dataIndex: 'Minimum',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('minDepositAmount') : i18n.t('minWithdrawingAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.isNumber(val) ? common.toFormatNumber(val) : '-'
          },
          {
            dataIndex: 'Maximum',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('maxDepositAmount') : i18n.t('maxWithdrawingAmount'),
            align: 'right',
            width: 200,
            render: (val) => common.isNumber(val) ? common.toFormatNumber(val) : '-'
          },
          {
            title: i18n.t('paymentFeePercentageTransactionFee'),
            align: 'right',
            width: 200,
            render: (_, record: any) => (
              <>{`${common.convertedToPercentage01(record.Rate)} %`} / {common.toFormatNumber(record.HandlingFee, 2)}</>
            )
          },
          {
            dataIndex: 'ExchangeRate',
            title: i18n.t('exchangeRate'),
            align: 'right',
            width: 150,
            render: (val) => common.toFormatNumber(val || 0, 2)
          },
          {
            dataIndex: 'CryptoAddress',
            title: i18n.t('userAddress'),

            width: 250,
            render: (val) => (
              <Row align="middle" justify="space-between">
                <Col>{val}</Col>
                <Col><CopyButton text={val} /></Col>
              </Row>
            )
          },
          {
            dataIndex: 'QR_Code',
            title: i18n.t('QRcode'),
            className: type === TRANSFER_TYPE.deposit ? '' : 'hide ',
            align: 'center',
            width: 100,
            render: (val) => val ? <Image src={val} width={30} height={30} /> : '-'
          },
          {
            dataIndex: 'TotalCount',
            title: i18n.t('totalTransactionsToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p('20901') && type === TRANSFER_TYPE.deposit
                    ? <Button type="link" onClick={() => {
                      dispatch(setSearchHistory({ text: { bankName: record.Crypto, bankDisplayName: record.CustomName } }));
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val, 0)}
                    </Button>
                    : (
                      $p('20601') && type === TRANSFER_TYPE.withdrawal
                        ? <Button type="link" onClick={() => {
                          dispatch(setSearchHistory({ text: { bankName: record.Crypto, bankDisplayName: record.CustomName } }));
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val, 0)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'TotalAmount',
            title: type === TRANSFER_TYPE.deposit ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
            width: 150,
            align: 'right',
            render: (val, record: any) => (
              <>
                {
                  $p('20901') && type === TRANSFER_TYPE.deposit
                    ? <Button type="link" onClick={() => {
                      dispatch(setSearchHistory({ text: { bankName: record.Crypto, bankDisplayName: record.CustomName } }));
                      navigate(`/finance/transfer-depositTotal/${record.DepositMerchantId}`);
                    }}>
                      {common.toFormatNumber(val)}
                    </Button>
                    : (
                      $p('20601') && type === TRANSFER_TYPE.withdrawal
                        ? <Button type="link" onClick={() => {
                          dispatch(setSearchHistory({ text: { bankName: record.Crypto, bankDisplayName: record.CustomName } }));
                          navigate(`/finance/transfer-withdrawTotal/${record.WithdrawMerchantId}`);
                        }}>
                          {common.toFormatNumber(val)}
                        </Button>
                        : common.toFormatNumber(val, 0)
                    )
                }
              </>
            )
          },
          {
            dataIndex: 'Status',
            title: i18n.t('status'),
            align: "center",
            width: 100,
            render: (val) => <div className={colorChange(val)}>{i18n.t(TRANSFER_SETTING_STATUS[val])}</div>
          },
          {
            title: i18n.t('operation'),
            fixed: 'right',
            width: 100,
            render: (_, record: any) => (
              <>
                {
                  $p('20703') && type === TRANSFER_TYPE.deposit &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setAddCrypto(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p('20403') && type === TRANSFER_TYPE.withdrawal &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setAddCrypto(true);
                  }}>{i18n.t('edit')}</Button>
                }
                {
                  $p('20705') && type === TRANSFER_TYPE.deposit &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
                {
                  $p('20404') && type === TRANSFER_TYPE.withdrawal &&
                  <Button type="link" onClick={() => {
                    setCurrentCard(record);
                    setIsModalOpenDelete(true);
                  }}>{i18n.t('delete')}</Button>
                }
              </>
            ),
          },
        ]}
        pagination={false}
      />

      <DepositAmountSetting isOpen={isModalOpenDepositAmountSetting} close={() => setIsModalOpenDepositAmountSetting(false)} />
      <WithdrawalsAmountSetting isOpen={isModalOpenWithdrawalsAmountSetting} close={() => setIsModalOpenWithdrawalsAmountSetting(false)} />
      <PopupCrypto isOpen={addCrypto} close={() => setAddCrypto(false)} mutate={mutate} id={currentCard.Id} type={type} currentCard={currentCard} />
      <InquiryWindow isOpen={isModalOpenDelete} close={() => setIsModalOpenDelete(false)}
        msg={`${i18n.t('confirmDeletion')} ${currentCard.CustomName} ${i18n.t('isData')}`} action={submitDelete} />
    </>
  )
}

// 虛擬貨幣新增編輯
export const PopupCrypto = ({ isOpen, close, mutate, type, id, currentCard }: any) => {
  const { data: $s } = useSite();
  const mode = !id ? MODE.add : MODE.edit;

  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  const { data: DepositBankInfo, mutate: mutateDeposit, isValidating: depositValidating } = $get({
    url: 'api/depositmerchant/offline/one',
    params: { Id: id },
    allow: type === TRANSFER_TYPE.deposit && !!id
  });
  const { data: WithdrawalBankInfo, mutate: mutateWithdrawal, isValidating: withdrawValidating } = $get({
    url: 'api/withdrawmerchant/offline/one',
    params: { Id: id },
    allow: type === TRANSFER_TYPE.withdrawal && !!id
  });

  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen]);

  useEffect(() => {
    if ((DepositBankInfo || WithdrawalBankInfo) && isOpen) {
      const info = DepositBankInfo?.Data || WithdrawalBankInfo?.Data;
      form.setFieldsValue({
        Crypto: info.Crypto,
        CryptoType: info.CryptoType,
        CustomName: info.CustomName,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        ExchangeRate: info.ExchangeRate,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        CryptoAddress: info.CryptoAddress,
        Status: info.Status,
        Position: info.Position,
        MerchantId: info.DepositMerchantId || info.WithdrawMerchantId
      });
      info?.QR_Code && setImageData([
        {
          uid: info?.QR_Code,
          name: info?.QR_Code,
          url: info?.QR_Code,
        },
      ])
    }
  }, [DepositBankInfo, WithdrawalBankInfo, isOpen]);

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = (formData: any) => {
    const send: any = {
      Crypto: formData.Crypto,
      CryptoType: formData.CryptoType,
      CustomName: formData.CustomName,
      Rate: common.convertedToPercentage02(formData.Rate),
      HandlingFee: formData.HandlingFee,
      ExchangeRate: formData.ExchangeRate,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      CryptoAddress: formData.CryptoAddress,
      Status: formData.Status,
      Position: formData.Position
    }
    if (type === TRANSFER_TYPE.deposit) send.QR_Code = imageData[0] ? imageData[0].url : '';
    if (mode === MODE.add) {
      $post({
        url: type === TRANSFER_TYPE.deposit
          ? 'api/depositmerchant/offline/crypto/create'
          : 'api/withdrawmerchant/offline/crypto/create',
        send,
        success: () => {
          message.success(i18n.t('addSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE_ADD_DEPOSIT
      })
    } else if (mode === MODE.edit) {
      send.Id = id
      send.MerchantId = formData.MerchantId;
      $post({
        url: type === TRANSFER_TYPE.deposit
          ? 'api/depositmerchant/offline/crypto/update'
          : 'api/withdrawmerchant/offline/crypto/update',
        send,
        success: () => {
          message.success(i18n.t('editSuccess'));
          onClose();
          mutate();
        },
        resCode: RESPONSE_CODE_ADD_DEPOSIT
      })
    }
  }

  const onClose = () => {
    close();
    setTimeout(() => {
      form.resetFields();
      setImageData([]);
    }, 500);
  }

  return (
    <Modal
      title={mode === MODE.add ? i18n.t('add') : i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
      forceRender
    >
      <Spin spinning={depositValidating || withdrawValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{
          Status: ENABLE.enabled
        }}>
          <Form.Item className="display-none" name="MerchantId" />
          <Row gutter={[12, 12]} className="mb-1">
            <Col span={24}>
              <Form.Item name="Crypto" label={i18n.t('currency')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="CryptoType" label={i18n.t('networkType')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="ExchangeRate" label={i18n.t('exchangeRate')} rules={verify({ point: 2 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Minimum"
                label={type === TRANSFER_TYPE.deposit ? i18n.t('minDepositAmount') : i18n.t('minWithdrawingAmount')}
                rules={verify({ point: 4, min: 1, isShowCompareNumber: true })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                name="Maximum"
                label={type === TRANSFER_TYPE.deposit ? i18n.t('maxDepositAmount') : i18n.t('maxWithdrawingAmount')}
                rules={verify({ point: 4, zero: false, required: false, max: 999999999 })}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="CryptoAddress" label={i18n.t('userAddress')}
                rules={[
                  { required: true },
                  {
                    validator: async (r, value) => {
                      if (/[^\w\d]/g.test(value)) {
                        return Promise.reject(i18n.t('addressCanOnlyContainAlphanumeric'));
                      }
                      return Promise.resolve();
                    }
                  }
                ]}>
                <Input placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            {type === TRANSFER_TYPE.deposit &&
              <Col span={24}>
                <Form.Item name="QRCode" label={i18n.t('qrCode')}>
                  {id !== undefined && currentCard?.QR_Code !== "" ?
                    <UploadImage
                      name="QRCode"
                      url={'/upload/images/public/depositmerchant'}
                      preUrl={DepositBankInfo ? DepositBankInfo?.Data?.QR_Code : ''}
                      imageData={imageData}
                      setImageData={setImageData}
                    /> :
                    <UploadImage
                      name="QRCode"
                      url={'/upload/images/public/depositmerchant'}
                      imageData={imageData}
                      setImageData={setImageData}
                    />
                  }
                </Form.Item>
              </Col>
            }
            <Col span={12}>
              <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
                <Select
                  className="w-full"
                  options={common.enumToOptions(ENABLE)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="Position" label={i18n.t('sort')}
                rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Row align="middle" justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 三方出入款編輯(不用新增)
export const PopupEditMerchant = ({ isOpen, close, id, mutate, type }: any) => {
  const { permissions: $p } = useAccount();

  const [form] = useForm();
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);
  const { data: DepositMerchantInfo, mutate: mutateDeposit } = $get({
    url: 'api/depositmerchant/one',
    params: { Id: id },
    allow: !!id && type === TRANSFER_TYPE.deposit
  })
  const { data: WithdrawMerchantInfo, mutate: mutateWithdrawal } = $get({
    url: 'api/withdrawmerchant/one',
    params: { Id: id },
    allow: !!id && type === TRANSFER_TYPE.withdrawal
  })
  const info = DepositMerchantInfo?.Data || WithdrawMerchantInfo?.Data;

  useEffect(() => {
    if (isOpen) mutateOne();
  }, [isOpen])

  useEffect(() => {
    if ((DepositMerchantInfo || WithdrawMerchantInfo) && isOpen) {
      const info = DepositMerchantInfo?.Data || WithdrawMerchantInfo?.Data;
      form.setFieldsValue({
        Name: info.Name,
        CustomName: info.CustomName,
        GatewayCode: info.GatewayCode,
        Rate: common.convertedToPercentage01(info.Rate),
        HandlingFee: info.HandlingFee,
        Minimum: info.Minimum,
        Maximum: info.Maximum,
        Status: info.Status,
        Position: info.Position
      })
      info.QR_Code && setImageData([
        {
          uid: info.QR_Code,
          name: info.QR_Code,
          url: info.QR_Code,
        },
      ])
    }
  }, [DepositMerchantInfo, WithdrawMerchantInfo, isOpen])

  const mutateOne = () => {
    mutateDeposit();
    mutateWithdrawal();
  }

  const onFinish = (formData: any) => {
    const send: any = {
      Id: id,
      CustomName: formData.CustomName,
      Rate: Number(common.convertedToPercentage02(formData.Rate)),
      HandlingFee: formData.HandlingFee,
      Minimum: formData.Minimum,
      Maximum: formData.Maximum,
      Status: formData.Status,
      Position: formData.Position
    }
    if (type === TRANSFER_TYPE.deposit) {
      send.QR_Code = imageData[0] ? imageData[0].url : '';
      $post({
        url: 'api/depositmerchant/update',
        send,
        success: () => {
          message.success(i18n.t('editSuccess'));
          mutate();
          onClose();
        },
        resCode: RESPONSE_CODE_MERCHANT
      })
    } else {
      $post({
        url: 'api//withdrawmerchant/update',
        send,
        success: () => {
          message.success(i18n.t('editSuccess'));
          mutate();
          onClose();
        },
        resCode: RESPONSE_CODE_MERCHANT
      })
    }
  }

  const onClose = () => {
    form.resetFields();
    setImageData([]);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="Name" label={i18n.t('thirdPartName')} rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="CustomName" label={i18n.t('displayName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="GatewayCode" label={i18n.t('channelName')} rules={[{ required: true }]}>
              <Input placeholder={`${i18n.t('inputData')}`} disabled />
            </Form.Item>
          </Col>
          {/* 金流費率 */}
          <Col span={12}>
            <Form.Item name="Rate" label={i18n.t('cashFlowRate')} rules={verify({ max: 100 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} addonAfter={'%'} disabled={(!$p('99999')) && (info?.Name === "369支付1")} />
            </Form.Item>
          </Col>
          {/* 手續費 */}
          <Col span={12}>
            <Form.Item name="HandlingFee" label={i18n.t('processingFee')} rules={verify({ point: 4 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={(!$p('99999')) && (info?.Name === "369支付1")} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Minimum" label={type === TRANSFER_TYPE.deposit ? i18n.t('minDepositAmount') : i18n.t('minWithdrawAmount')}
              rules={verify({ point: 4, min: 1, isShowCompareNumber: true })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="Maximum"
              label={type === TRANSFER_TYPE.deposit ? i18n.t('maxDepositAmount') : i18n.t('maxWithdrawAmount')}
              rules={verify({ point: 4, zero: false, required: false, max: 999999999 })}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          {type === TRANSFER_TYPE.deposit &&
            <Col span={24}>
              <Form.Item name="QRCode" valuePropName="fileList" label={i18n.t('qrCode')}>
                <UploadImage
                  name="QRCode"
                  url={'/upload/images/public/depositmerchant'}
                  imageData={imageData}
                  setImageData={setImageData}
                />
              </Form.Item>
            </Col>
          }
          <Col span={12}>
            <Form.Item name="Status" label={i18n.t('status')} rules={[{ required: true }]}>
              <Select
                className="w-full"
                options={common.enumToOptions(ENABLE)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="Position" label={i18n.t('sort')}
              rules={[...verify({ point: 0 }), { max: 99, type: 'number' }]}>
              <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
            </Form.Item>
          </Col>
          <Col span={24} className="mt-1">
            <Row align="middle" justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal >
  )
}

// 存款金額快捷設定
export const DepositAmountSetting = ({ isOpen, close }: any) => {

  const [form] = useForm();
  const { data, mutate, isValidating } = $get({
    url: 'api/depositmerchant/quick/info'
  });

  useEffect(() => {
    if (isOpen) mutate();
    if (data && isOpen) {
      const code = JSON.parse(data?.Data.Code)
      form.setFieldsValue({
        amount0: code[0],
        amount1: code[1],
        amount2: code[2],
        amount3: code[3],
        amount4: code[4],
        amount5: code[5],
        amount6: code[6],
      });
    }
  }, [data, isOpen]);

  const onFinish = (formData: any) => {
    const Amounts = [
      formData.amount0,
      formData.amount1,
      formData.amount2,
      formData.amount3,
      formData.amount4,
      formData.amount5
    ];
    $post({
      url: 'api/depositmerchant/quick/update',
      send: {
        Amounts
      },
      success: () => {
        message.success(i18n.t('saveSuccess'));
        onClose();
      },
    })
  }

  const onClose = () => {
    close();
  }

  const validator: any = async (d: any, val: number, i: number) => {
    const _val: number = Number(val);
    const upperInputValue: number = Number(form.getFieldValue(`amount${i - 1}`));
    const downerInputValue: number = Number(form.getFieldValue(`amount${i + 1}`));
    const min: number = 1;
    const max: number = 500000;

    // 需大於等於min
    if (_val < min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${common.toFormatNumber(min, 0)}`}</p>);
    // 需小於等於max
    else if (_val > max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${common.toFormatNumber(max, 0)}`}</p>);
    // 需大於上層
    else if (_val <= upperInputValue && (upperInputValue && upperInputValue !== 0) && upperInputValue <= max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${common.toFormatNumber(upperInputValue, 0)}`}</p>);
    // 需小於下層
    else if (_val >= downerInputValue && (downerInputValue && downerInputValue !== 0) && downerInputValue >= min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${common.toFormatNumber(downerInputValue, 0)}`}</p>);

    else return Promise.resolve();
  }

  const validateOther: any = async (i: number) => {
    form.validateFields([`amount${i - 1}`, `amount${i + 1}`]);
  }

  const { permissions: $p } = useAccount();
  const isEdit = $p('20703');

  return (
    <Modal
      title={i18n.t('depositAmountQuickSetting')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="amount0" label={`${i18n.t('quickAmount')}1`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 0) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(0)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount1" label={`${i18n.t('quickAmount')}2`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 1) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(1)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount2" label={`${i18n.t('quickAmount')}3`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 2) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(2)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount3" label={`${i18n.t('quickAmount')}4`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 3) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(3)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount4" label={`${i18n.t('quickAmount')}5`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 4) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(4)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount5" label={`${i18n.t('quickAmount')}6`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 5) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(5)} />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Row align="middle" justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                {isEdit && <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                </Col>}
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

// 出款金額快捷設定
export const WithdrawalsAmountSetting = ({ isOpen, close }: any) => {

  const [form] = useForm();
  const { data, mutate, isValidating } = $get({
    url: 'api/withdrawmerchant/quick/info'
  });

  useEffect(() => {
    if (isOpen) mutate();
    if (data && isOpen) {
      const code = JSON.parse(data?.Data.Code)
      form.setFieldsValue({
        amount0: code[0],
        amount1: code[1],
        amount2: code[2],
        amount3: code[3],
        amount4: code[4],
        amount5: code[5],
        amount6: code[6],
      });
    }
  }, [data, isOpen]);

  const onFinish = (formData: any) => {
    const Amounts = [
      formData.amount0,
      formData.amount1,
      formData.amount2,
      formData.amount3,
      formData.amount4,
      formData.amount5
    ];
    $post({
      url: 'api/withdrawmerchant/quick/update',
      send: {
        Amounts
      },
      success: () => {
        message.success(i18n.t('saveSuccess'));
        onClose();
      },
    })
  }

  const onClose = () => {
    close();
  }

  // 聖經: 不得小於上層 大於下層 有大小
  const validator: any = async (d: any, val: number, i: number) => {
    const _val: number = Number(val);
    const upperInputValue: number = Number(form.getFieldValue(`amount${i - 1}`));
    const downerInputValue: number = Number(form.getFieldValue(`amount${i + 1}`));
    const min: number = 1;
    const max: number = 500000;

    // 需大於等於min
    if (_val < min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThanOrEqualTo')}${common.toFormatNumber(min, 0)}`}</p>);
    // 需小於等於max
    else if (_val > max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThanOrEqualTo')}${common.toFormatNumber(max, 0)}`}</p>);
    // 需大於上層
    else if (_val <= upperInputValue && (upperInputValue && upperInputValue !== 0) && upperInputValue <= max) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeGreaterThan')}${common.toFormatNumber(upperInputValue, 0)}`}</p>);
    // 需小於下層
    else if (_val >= downerInputValue && (downerInputValue && downerInputValue !== 0) && downerInputValue >= min) return Promise.reject(() =>
      <p style={{ margin: 0 }}>{`${i18n.t('mustBeLessThan')}${common.toFormatNumber(downerInputValue, 0)}`}</p>);

    else return Promise.resolve();
  }

  const validateOther: any = async (i: number) => {
    form.validateFields([`amount${i - 1}`, `amount${i + 1}`]);
  }

  const { permissions: $p } = useAccount();
  const isEdit = $p('20403');

  return (
    <Modal
      title={i18n.t('withdrawalsAmountQuickSetting')}
      open={isOpen}
      onCancel={onClose}
      centered
      footer={false}
      width={450}
      forceRender
    >
      <Spin spinning={isValidating}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item name="amount0" label={`${i18n.t('quickAmount')}1`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 0) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(0)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount1" label={`${i18n.t('quickAmount')}2`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 1) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(1)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount2" label={`${i18n.t('quickAmount')}3`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 2) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(2)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount3" label={`${i18n.t('quickAmount')}4`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 3) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(3)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount4" label={`${i18n.t('quickAmount')}5`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 4) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(4)} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="amount5" label={`${i18n.t('quickAmount')}6`} rules={[
                ...verify({ point: 4 }), { validator: (d, val) => validator(d, val, 5) }]}>
                <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} disabled={!isEdit}
                  onChange={() => validateOther(5)} />
              </Form.Item>
            </Col>
            <Col span={24} className="mt-1">
              <Row align="middle" justify="center" gutter={[12, 12]}>
                <Col>
                  <Button onClick={onClose}>{i18n.t('cancel')}</Button>
                </Col>
                {isEdit && <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
                </Col>}
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Modal>
  )
}

