import { EllipsisOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Decimal from 'decimal.js';
import { MEMBER_BANK_STATE, STATE, WITHDRAW_STATE } from "enum/state";
import i18n from "i18n";
import Cookies from "js-cookie";
import { GAME_SETTING } from "../enum/game";
import { COOKIE } from 'constants/cookie';

export enum IP_STATE {
  safe = 1,
  warn = 7,
  danger = 8,
}

// 銀行末四碼
export function displayLastFourCharacters(input: string) {
  if (input.length > 0) {
    var lastFourCharacters = input.substring(input.length - 4);
    return lastFourCharacters;
  } else {
    return ''
  }
}

// 數字格式
// 預設四捨五入; 無條件捨去roundType帶入'floor'; 無條件進位roundType帶入'ceil'
export function toFormatNumber(num: any, fraction: number = 4, roundType?: 'floor' | 'ceil'): string {
  const toNum = isNaN(num) ? 0 : Number(num);
  const factor = Math.pow(10, fraction);
  let roundedNum: number;
  switch (roundType) {
    case 'ceil':
      roundedNum = Math.ceil(toNum * factor) / factor;
      break;
    case 'floor':
      roundedNum = Math.floor(toNum * factor) / factor;
      break;
    default:
      roundedNum = Math.round(toNum * factor) / factor;
      break;
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(roundedNum);
  return formattedNumber;
}

export function decimalNumber(input: string): boolean {
  const regex = /^\d+(\.\d{1,2})?$/;
  return regex.test(input)
}

export function leftPad(str: string, len: number, char: string) {
  while (str.length < len) {
    str = char + str;
  }
  return str;
}

// 正的是黑色
export function numColor01(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) > 0)
    return 'color-up'
  else if (Number(num) < 0)
    return 'color-down'
  else
    return 'unchange'
}

export function numColor02(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) <= IP_STATE.safe)
    return 'color-safe'
  else if (Number(num) <= IP_STATE.warn)
    return 'color-warn'
  else if (Number(num) >= IP_STATE.danger)
    return 'color-danger'
}

// 正的是綠色
export function numColor03(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-0'
  else if (Number(num) > 0)
    return 'color-up-0'
  else
    return 'color-04'
}

export function numColor04(type: number) {
  switch (type) {
    case WITHDRAW_STATE.approvaled:
      return 'color-pass'
    case WITHDRAW_STATE.reject:
    case WITHDRAW_STATE.fail:
      return 'color-reject'
    case WITHDRAW_STATE.pendingSupervisorReview:
    case WITHDRAW_STATE.pendingServiceReview:
    case WITHDRAW_STATE.pendingFinancialReview:
    case WITHDRAW_STATE.financialReview:
      return 'color-reviewing'
    case WITHDRAW_STATE.processing:
    case WITHDRAW_STATE.paymentTerminalWaiting:
      return 'color-padding'
  }
}

// 標籤式
export function numColor05(input: any) {
  const num = typeof input === "string" ? input.replaceAll(',', '') : input;

  if (Number(num) < 0)
    return 'color-down-tag'
  else if (Number(num) > 0)
    return 'color-up-tag'
  else
    return 'color-tag'
}

// FIXME: 輸入狀態都不同 是否能整合
// 狀態顏色
export function stateColor(state: number) {
  switch (state) {
    case STATE.pass:
      return 'color-pass'
    case STATE.reject:
      return 'color-reject'
    case STATE.padding:
      return 'color-padding'
    default: return ''
  }
}

export function stateColorBank(state: number) {
  switch (state) {
    case MEMBER_BANK_STATE.waitVerify:
      return 'color-padding';
    case MEMBER_BANK_STATE.verificationSuccessfulOpen:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationSuccessfulClose:
      return 'color-pass';
    case MEMBER_BANK_STATE.verificationFailed:
      return 'color-reject';
    case MEMBER_BANK_STATE.delete:
      return 'color-down-0';
  }
}

// 轉換成百分比 1 => 100
export function convertedToPercentage01(num: number | string): string {
  if (!num && num !== 0) return '';

  let decimalValue;
  if (typeof num === 'undefined') return ''
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    decimalValue = new Decimal(parsedValue);
  }

  decimalValue = new Decimal(num);

  return decimalValue.mul(100).toFixed(2).toString();
}

// 轉換成百分比 100 => 1
export function convertedToPercentage02(num: number | string): string {
  let decimalValue;
  if (typeof num === 'string') {
    const parsedValue = parseFloat(num);
    if (!isNaN(parsedValue)) {
      decimalValue = new Decimal(parsedValue).toDP(2);
    } else {
      throw new Error('Invalid input: Not a valid number or string that can be parsed into a number.');
    }
  } else if (typeof num === 'number') {
    decimalValue = new Decimal(num).toDP(2);
  } else {
    throw new Error('Invalid input: Input must be a number or a string that can be parsed into a number.');
  }

  return decimalValue.div(100).toString();
}

// 欄位驗證
export function verify({
  required = true,
  point = 2,
  max = null,
  min = null,
  negative = false,
  zero = true,
  isShowCompareNumber,
  message
}: {
  required?: boolean;
  point?: number;
  max?: number | string | null;
  min?:  number | string | null;
  negative?: boolean;
  zero?: boolean;
  isShowCompareNumber?: boolean;
  message?: string | null | undefined;
}) {
  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = new RegExp('^-?\\d{1,100}(\\.\\d{0,0})?$');
        let msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('onlyIntegersCanBeEntered')}`;

        if (point === 2) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,2})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToTwoDecimalPlaces')}`;
        } else if (point === 4) {
          regex = new RegExp('^-?\\d{1,100}(\\.\\d{1,4})?$');
          msg = `${i18n.t('mustBeGreaterThanOrEqualTo')}0${i18n.t('supportsUpToFourDecimalPlaces')}`;
        } else if (point === 5) {
          regex = new RegExp('[a-zA-Z]{1}[a-zA-Z0-9]{3,11}$');
          msg = `4~12${i18n.t('requiresEnglishAndNumbers')}`;
        } else if (point === 6) {
          regex = new RegExp('[a-zA-Z0-9*_-]{4,20}$');
          msg = `4~20${i18n.t('requiresEnglishAndNumbersSupportDash')}`;
        } else if (point === 7) {
          regex = new RegExp('^[0-9]+$');
          msg = i18n.t('invalidFormat');
        }

        // 正整數禁止符號
        if (value && point === 0 && !(new RegExp('^-?\\d+(\\.\\d+)?$')).test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 只能是正整數
        if (value && point === 0 && ((!negative && Number(value) < 0) || !regex.test(value))) {
          return Promise.reject(i18n.t('needBePositiveInteger'));
        }

        // 只能是數字
        if (isNaN(value) && value && point !== 5 && point !== 6 && point !== 7) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 不能為0 但選填
        if (!zero && (typeof value !== 'undefined' && value !== null && value !== '') && Number(value) === 0) {
          return Promise.reject(`${i18n.t('mustBeGreaterThan')}0`);
        }

        // 正數
        if (!negative && Number(value) < 0) {
          return Promise.reject(`${i18n.t('needBePositiveNumber')}`);
        }

        // 小數點
        if (value && !regex.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        }

        // 超過最大
        if (max !== null && parseFloat(value) > parseFloat(max as string)) {
          return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${max}`);
        }
        // 限制最小
        if (min !== null && parseFloat(value) < parseFloat(min as string)) {
          return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${min}`);
        }

        return Promise.resolve();
      }
    }
  ];
}

type VerifyType = 'number';

interface VerifyOptions {
  required?: boolean;
  point?: number;
  min?: number | null;
  emin?: number | null;
  max?: number | null;
  emax?: number | null;
  type?: VerifyType | null;
}

// 欄位驗證(改)
// 預設: 需大於0的小數點4位必填
export function verifyNew({
  required = true,
  point = 4,
  min = 0,
  emin = null,
  max = null,
  emax = null,
  type = null,
}: VerifyOptions) {
  return [
    { required: required, message: `${i18n.t('required')}` },
    {
      validator: async (record: any, value: any) => {

        let regex = /^-?\d+(\.\d{0,0})?$/;

        // 驗證型別
        if (type === 'number') regex = /^[0-9]+$/;
        // 驗證小數點
        else if (point === 2) regex = /^-?\d+(\.\d{0,2})?$/;
        else if (point === 4) regex = /^-?\d+(\.\d{0,4})?$/;

        // 一般格式驗證
        if (point !== 0 && value && !regex?.test(value)) {
          return Promise.reject(i18n.t('invalidFormat'));
        // 內容只能是正整數(可為0)
        } else if (
          (point === 0 && value && !regex?.test(value)) ||
          (point === 0 && min === 0 && emin === 0 && parseFloat(value) < emin)
        ) {
          return Promise.reject(i18n.t('needBePositiveInteger'));
        } 
        // 內容只能是整數(可為0)
        // else if (
        //   (point === 0 && value && !regex?.test(value)) &&
        //   (min === null)
        // ){
        //   return Promise.reject(i18n.t('onlyIntegersCanBeEntered'));
        // }
     
        if (!type) {
          // 最小值(大於)
          if (min !== null && emin === null && parseFloat(value) <= min) {
            return Promise.reject(`${i18n.t('mustBeGreaterThan')}${min}`);
          // 最小值(大於等於)
          } else if (emin !== null && parseFloat(value) < emin) {
            return Promise.reject(`${i18n.t('mustBeGreaterThanOrEqualTo')}${emin}`);
          }

          // 最大值(小於)
          if (max !== null && emax === null && parseFloat(value) >= max) {
            return Promise.reject(`${i18n.t('mustBeLessThan')}${max}`);
          // 最大值(小於等於)
          } else if (emax !== null && parseFloat(value) > emax) {
            return Promise.reject(`${i18n.t('mustBeLessThanOrEqualTo')}${emax}`);
          }
        }
      
        return Promise.resolve();
      }
    }
  ];
}

// enum 轉成下拉選單(_, 排除項目)
export function enumToOptions(e: any, exclude: any = []) {
  return Object.keys(e)
    .filter(v => isNaN(Number(v)) && !exclude.includes(e[v]))
    .map((k: any) => ({ value: e[k], label: i18n.t(k) }))
}

// 顯示幾代與代理名稱
export function agentLevelWord(level: any) {
  return `${level === 0 ? i18n.t('totalAg') : level}${i18n.t('agents')}`;
}

export function transferTimeFormat(time: number): string {
  return dayjs(time * 1000).format('YYYY-MM-DD HH:mm');
}

export const $mobile = window.innerWidth < 1300
// 螢幕: 1920
// 筆電: 1440
// pad: 1024
// phone: 430

// 多語系切換 i: jsonString o:name string
export function gameNameTransfer(json: string, defaultName: string) {
  const userLang = Cookies.get(COOKIE.LANG);
  const data = json ? JSON.parse(json) : [];
  const transfer = data.find((item: { lang: string }) => item.lang === userLang);
  return transfer ? transfer.name : defaultName;
}

// 切換語系
export async function langChange(value: string, reload: boolean) {
  Cookies.set('bo_lang', value);
  Cookies.set('lang-online-service', value);
  if (reload) {
    window.location.reload();
  } else {
    i18n.changeLanguage(value);
  }
}

dayjs.extend(utc);
dayjs.extend(timezone);

export function timeL2S(timeString: string) {
  // 送出資料時 要告知當地時區
  if (timeString) return dayjs(timeString).format('YYYY-MM-DDTHH:mm:ssZ');
  else return timeString;
}

// 出字串沒錯
export function timeL2SDay(day: any, format: string = 'YYYY-MM-DDTHH:mm:ssZ') {
  if (day) return day.format(format);
  else return day;
}

export function timeS2L(timeString: string, format: string = 'YYYY-MM-DD HH:mm:ss') {
  // 前端收到後 要加上本地時區 GMT+08:00:00
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (!timeString || timeString === '0000-00-00 00:00:00') return '-';
  else if (timeString) return dayjs.tz(timeString, serverZone).tz().format(format);
  else return timeString;
}

export function timeS2LUnix(unix: number, format?: string) {
  const timeString = dayjs.unix(unix).format('YYYY-MM-DDTHH:mm:ss');
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (unix) {
    if (format) return dayjs.tz(timeString, serverZone).tz().format(format);
    else return dayjs.tz(timeString, serverZone).tz().unix();
  }
  else return unix;
}

// 字串出days
export function timeS2LDay(timeString: any) {
  const serverZone = window?.serverZone || "Asia/Taipei";
  if (timeString) return dayjs.tz(timeString, serverZone).tz()
  else return timeString;
}

// 操作記錄-操作選單(沒用到)
export function filterOperation(ary: any, find: any) {
  return ary.filter((item: any) => item.ManagementId === find)
}

// BE GM 特規
export function specialProviderName(input: string | any, change: string, changeOther?: string) {
  return input === 'SELF' ? `${change}(${changeOther})` : input
}

// 比較兩個物件內容是否相同
export function objectsEqual(a: any, b: any) {
  let result = true;
  const av = Object.values(a);
  const bv = Object.values(b);
  av.forEach((v, i) => {
    if (v !== bv[i]) result = false;
  })
  return result;
}

// 整理逗號字串雜訊
export function cleanCommaString(str: string) {
  const dirty = str.split(',');
  const clean = dirty.map((item: any) => item.trim()).filter((item: any) => item !== '');
  return clean.join(',');
}

// 移除陣列重複項目
export function removeDuplicates(arr: string[]) {
  return arr.filter((item: any, index: number) => arr.indexOf(item) === index);
}

// 遊戲id陣列回傳遊戲名稱陣列
export function gameNameArray(game: any[]) {
  const nameList: any = [];

  (game || []).forEach(g => {
    nameList.push(g ? gameNameTransfer(g.Lang, g.Name) : '-');
  });
  // (ids || []).forEach(id => {
  //   const theGame = (game || []).find((item: any) => item.Id === id);
  //   if (theGame) {
  //     // const name = JSON.parse();
  //     // lang.find(l => l.lang === '');
  //     nameList.push(theGame.Name);
  //   };
  // });
  // if (nameList.length > 3) {
  //   nameList.length = 3;
  //   nameList.push('...')
  // }
  //
  // return nameList.join(' / ');
  return (
    <>
      {
        nameList?.length > 3
          ? <>
            {
              nameList?.map((item: any, i: any) => {
                return <span key={i}>{i <= 2 && `${item}／`}</span>
              })
            }
            <span>
              <Tooltip placement='right' title={
                <>
                  {
                    nameList.map((item: any, i: any) => {
                      return <div key={i}>{i >= 3 && `${item}`}</div>
                    })
                  }
                </>
              }>
                <EllipsisOutlined />
              </Tooltip>
            </span>
          </>
          :
          <span>{nameList?.join('/')}</span>
      }
    </>
  )
}

// 下拉選單模糊搜尋
export const selectFuzzyFilter = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

// 遊戲設置陣列轉換
export function convertGameSettings(input: any): any {
  const inputArray = JSON.parse(input || '[]');
  return inputArray?.map((item: any) => {
    switch (item) {
      case GAME_SETTING.newGame:
        return i18n.t('newGame');
      case GAME_SETTING.hotGames:
        return i18n.t('hotGames');
      case GAME_SETTING.recommend:
        return i18n.t('recommend');
      case GAME_SETTING.none:
        return '-';
      default:
        return '';
    }
  }).join();
}

// 檢驗是否是數字, 0為真
export function isNumber(input: any) {
  return input !== null && input !== '' && !isNaN(Number(input));
}

// 檢測圖片長寬
export const determineImageAttributes = (file: any, w: number, h: number, { pass, fail }: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e: any) {
      const image: any = new Image();
      image.src = e.target.result;
      image.onload = function () {
        if (!h || (this.width <= w && this.height <= h)) resolve(true);
        else reject();
      };
    };
  })
  .then(pass).catch(fail);
}